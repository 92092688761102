import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1img.png";
import HIW2img from "images/HIW2img.png";
import HIW3img from "images/HIW3img.png";
import HIW4img from "images/HIW4img.png";
import VectorImg from "images/VectorHIW.svg";
import Badge from "shared/Badge/Badge";



import "./CompletSol.css"

export interface SectionHowItWorkProps {
  className?: string;
  data?: typeof DEMO_DATA[0][];
}

const DEMO_DATA = [
  {
    id: 1,
    img: "images/store.gif",
    imgDark: HIW1img,
    title: "Multibrand Showroom",
    desc: "Search and Select from Multiple Brands, Category Products",
  },
  {
    id: 2,
    img: "images/dollar.gif",
    imgDark: HIW2img,
    title: "Finance",
    desc: "Get the best deal on finance on your new or used machinery",
  },
  {
    id: 3,
    img: "images/phone.gif",
    imgDark: HIW3img,
    title: "Service & Parts",
    desc: "Raise a request and find an expert service engineer at your doorstep in 6 hours*",
  },
  {
    id: 4,
    img: "images/rent.gif",
    imgDark: HIW4img,
    title: "Rental Business",
    desc: "Add your machine on our network and get year-round business demand",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <div className="relative grid sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-20">
        {data.map((item: typeof DEMO_DATA[number], index: number) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-4 sm:mb-3 max-w-[90px] mx-auto"
              className="rounded-3xl"
              src={item.img}
            />
            <div className="text-center  space-y-2">
              {/* <Badge
                name={`Step ${index + 1}`}
                color={
                  !index
                    ? "red"
                    : index === 1
                    ? "indigo"
                    : index === 2
                    ? "yellow"
                    : "purple"
                }
              /> */}
              <h3 className="text-sm font-semibold">{item.title}</h3>
              <span className="block text-slate-600 dark:text-slate-400 CompleteSol_text_desc">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
