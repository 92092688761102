import axios from "axios"
import { config, tld } from "services/globalvariables/globalvariables"
// Required Endpoints imports 
import { brandlist,homebanner, popular_machinery, vdo_listing, blog_listing } from "services/api/allApiHub"
// banner list home page
export const getBannerList = async () => {
    try { return (await axios.get(`${tld}${homebanner}`, config)).data.data }
    catch (err) { console.log(err) }
}


// Popular Machinery Listing
export const getProductList = async () => {
    try { return (await axios.get(`${tld}${popular_machinery}`, config)).data.data }
    catch (err) { console.log(err) }
}
// Get Video listings
export const getVideotList = async () => {
    try { return (await axios.get(`${tld}${vdo_listing}`, config)).data.data }
    catch (err) { console.log(err) }
}

// Home page Blog listing
export const getAllBlogList = async () => {
    try { return (await axios.get(`${tld}${blog_listing}`, config)).data.data }
    catch (err) { console.log(err) }
}



export const getBrandList = async () => {
    try { return (await axios.get(`${tld}${brandlist}`, config)).data.data }
    catch (err) { console.log(err) }
}