import './styledetailpage.css'
const DetailPageShimmer = () => {
    return <>
        <div className="container">
            <div class="container1">
                <div class="left-section shimmer-effect">
                    <h2 class="shimmer-box mt-4"></h2>
                    <p class="shimmer-box mt-4"></p>
                    <p class="shimmer-box mt-4"></p>
                    <button class="inquire-btn shimmer-box mt-4"></button>
                    <h2 class="shimmer-box mt-4"></h2>
                    <p class="shimmer-box mt-4"></p>
                    <p class="shimmer-box mt-4"></p>
                    <button class="inquire-btn shimmer-box mt-4"></button>
                </div>
                <div class="right-section shimmer-effect">
                    <h2 class="shimmer-box mt-4"></h2>
                    <p class="shimmer-box mt-4"></p>
                    <p class="shimmer-box mt-4"></p>
                    <button class="inquire-btn shimmer-box mt-4"></button>
                    <h2 class="shimmer-box mt-4"></h2>
                    <p class="shimmer-box mt-4"></p>
                    <p class="shimmer-box mt-4"></p>
                    <button class="inquire-btn shimmer-box mt-4"></button>
                </div>
            </div>
        </div>
    </>
}

export default DetailPageShimmer
