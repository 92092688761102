import './shimmer.css'

const ShimmerUI = () => {
    return <div class="product-card">



    </div>

}


export default ShimmerUI