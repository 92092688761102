import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useForm, Controller, FormProvider, useFormContext } from "react-hook-form";

 const MachineCategory = () => {
    const [category, setCategory] = useState([])
    const [subcategory, setSubCategory] = useState([]);
    const [brand, setBrand] = useState([])
    const { control, formState: { errors, defaultValues }, setValue, register } = useFormContext();

    const getCategoryList = async () => {
        const config = { headers: { "X-Api-Key": `${process.env.REACT_APP_API_KEY}` } };
        try {
            let URL = `${process.env.REACT_APP_HOST}`
            let categorys = `${URL}/fleetowners/v1/view_categories/?key=qwerty`
            let subcategory = `${URL}/showroom/api/v1/sub_category/all`
            let brand = `${URL}/showroom/api/v1/brand/all`
            const RESULT4 = await axios.get(categorys, config)
            const RESULT5 = await axios.get(subcategory, config)
            const RESULT6 = await axios.get(brand, config)
            setCategory(RESULT4?.data?.data);
            setSubCategory(RESULT5?.data?.data);
            setBrand(RESULT6?.data?.data);
        }
        catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        getCategoryList();
    }, [])
    return (
        <>
            <div className="min-h-80 mt-8">
                <Controller
                    control={control}
                    register={register}
                    setValue={setValue}
                    name="machine_category_id"
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel id="MachineCategory">Machine-Category</InputLabel>
                            <Select
                                labelId="Machine Category"
                                id="demo-simple-select-MachineCategory"
                                label="Machine Category"
                                {...register("machine_category_id", { required: "*required" })}
                                {...field}
                                error={Boolean(errors?.machine_category_id)}
                                sx={{ width: "100%" }}
                            >
                                {
                                    category.map((item, index) => {
                                        return <MenuItem key={index} name={item.machine_category_id} value={item.machine_category_id}>{item.machine_category_name}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText sx={{ color: "red" }}>{errors?.machine_category_id?.message}</FormHelperText>
                        </FormControl>

                    )}
                />

                {/* --------------------------------------------------------------------
                Machine Sub Category
                -------------------------------------------------------------------- */}

                <Controller
                    control={control}
                    register={register}
                    setValue={setValue}
                    name="sub_category"
                    render={({ field }) => (
                        <FormControl fullWidth sx={{ marginTop: "20px" }}>
                            <InputLabel id="MachineCategory">Machine-Subcategory</InputLabel>
                            <Select
                                labelId="Machine Category"
                                id="demo-simple-select-MachineCategory"
                                label="Machine Category"
                                {...register("sub_category", { required: "*required." })}
                                {...field}
                                error={Boolean(errors?.sub_category)}
                                helperText={errors?.sub_category?.message}
                                sx={{ width: "100%" }}
                            >
                                {
                                    subcategory.map((item, index) => {
                                        return <MenuItem key={index} name={item.sub_category} value={item.id}>{item.sub_category}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText sx={{ color: "red" }}>{errors?.sub_category?.message}</FormHelperText>
                        </FormControl>

                    )}
                />

                <Controller
                    control={control}
                    name="brand_id"
                    rules={{ required: "*required." }}
                    render={({ field }) => (
                        <FormControl fullWidth sx={{ marginTop: "20px" }}>
                            <InputLabel id="MachineBrand">Machine Brand</InputLabel>
                            <Select
                                labelId="Machine Brand"
                                id="Machine-Brand"
                                label="Machine Brand"
                                {...field}
                                error={Boolean(errors?.brand_id)}
                                helperText={errors?.brand_id?.message}
                                sx={{ width: "100%" }}
                            >
                                {
                                    brand.map((item, index) => {
                                        return <MenuItem key={index} name={item.brand_id} value={item.brand_id}>{item.brand_name}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText sx={{ color: "red" }}>{errors?.brand_id?.message}</FormHelperText>
                        </FormControl>

                    )}
                />

                <Controller
                    control={control}
                    name="machine_model"
                    rules={{ required: "*required.", maxLength: 30 }}
                    render={({ field }) => (
                        <TextField
                            id="phone-number"
                            label="Machine Model"
                            variant="filled"
                            placeholder="Enter Machine Model"
                            fullWidth
                            margin="normal"
                            {...field}
                            error={Boolean(errors?.machine_model)}
                            helperText={errors?.machine_model?.message}
                        />
                    )}
                />
            </div>
        </>
    );
};

export default MachineCategory