
import TextField from '@mui/material/TextField';
import { useForm, Controller, FormProvider, useFormContext } from "react-hook-form";

const MachineInformation = () => {
    const { control, formState: { errors } } = useFormContext();
    return (
        <>
            <Controller
                control={control}
                name="reg_no"
                rules={{ required: "*required." }}
                render={({ field }) => (
                    <TextField
                        id="RTO-Registration"
                        label="RTO Registration"
                        placeholder="Enter Your RTO Registration Number"
                        fullWidth
                        margin="normal"
                        variant='filled'
                        {...field}
                        error={Boolean(errors?.reg_no)}
                        helperText={errors.reg_no?.message}
                    />
                )}
            />


            <small className='mt-2 block'>Purchase date</small>
            <Controller
                control={control}
                name="perchase_date"
                rules={{ required: "*required." }}
                render={({ field }) => (

                    <TextField
                        id="date"
                        variant="filled"
                        type='date'
                        placeholder='Date'
                        fullWidth
                        margin="normal"
                        {...field}
                        error={Boolean(errors?.perchase_date)}
                        helperText={errors.perchase_date?.message}
                    />
                )}
            />
            <Controller
                control={control}
                name="chassi_no"
                rules={{ required: "*required." }}
                render={({ field }) => (
                    <TextField
                        id="chasis-serial-Number"
                        label="Chasis serial Number"
                        rules={{ required: "*required." }}
                        variant="filled"
                        placeholder="Enter Chasis serial Number"
                        fullWidth
                        margin="normal"
                        {...field}
                        error={Boolean(errors?.chassi_no)}
                        helperText={errors.chassi_no?.message}
                    />
                )}
            />

            <Controller
                control={control}
                name="machine_number"
                rules={{ required: "*required." }}
                render={({ field }) => (
                    <TextField
                        id="machine-nick-name"
                        label="Machine nick Name"
                        variant="filled"
                        placeholder="Enter Your Machine nick Name"
                        fullWidth
                        margin="normal"
                        {...field}
                        error={Boolean(errors?.machine_number)}
                        helperText={errors.machine_number?.message}
                    />
                )}
            />


            <Controller
                control={control}
                name="engine_no"
                render={({ field }) => (
                    <TextField
                        id="Engine-Number"
                        label="Engine Number"
                        variant="filled"
                        placeholder="Enter Your Engine Number"
                        fullWidth
                        margin="normal"
                        {...field}
                        error={Boolean(errors?.engine_no)}
                        helperText={errors.engine_no?.message}
                    />
                )}
            />
        </>
    );
};

export default MachineInformation