import rightImg from "images/hero-right1.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}

      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>AgrotracDigital:About Us</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}

      <div className="container py-8 lg:py-8 md:min-h-screen">
      


        <section className="header1  text-white mb-10 py-20" id="header01-0" style={{ background: "aliceblue" }}>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 justify-center items-center">
              <div className="col-span-12 md:col-span-12 lg:col-span-7">
                <div className="text-wrapper">

                  <h1 className="text-4xl md:text-5xl lg:text-2xl font-bold mb-3 text-black">
                    Agrotrac Digital is a Buy, Sell, Rental Platform for Agricultural Machinery.
                  </h1>
                  <p className="text-lg md:text-xl lg:text-2xl pb-4 text-black">
                    Vision
                    To Become the Most Trusted Brand In the Agri Machinery and Technology Space
                  </p>
                  <div className="mt-5">
                    <a href="https://www.agrotracdigital.com/" className="btn btn-lg btn-white-outline text-lg md:text-xl lg:text-2xl text-black bg-gray-200 p-2 mt-4">Get Started</a>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-12 lg:col-span-5">
                <div className="image-wrapper">
                  <img className="w-full" src="assets/images/mbr.jpg" alt="agrotrac" title="" />
                </div>
              </div>
            </div>
          </div>
        </section>






        <section className="features8 py-16" id="features08-3" style={{ background: "aliceblue" }}>
          <div className="container mx-auto">
            <div className="mb-10 text-center">
              <h3 className="text-3xl font-bold mb-0">Our Vision</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="card">
                <div className="card-wrapper card1 p-6 bg-white rounded-lg shadow-md">
                  <div className="card-box align-left">
                    <div className="iconfont-wrapper">
                      <span className="text-4xl text-blue-500 mobi-mbri-cash"></span>
                    </div>
                    <h5 className="text-xl font-semibold mb-2">Marketing</h5>
                    <p className="text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <h6 className="text-lg"><a href="#" className="text-blue-500">Read more</a></h6>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-wrapper card2 p-6 bg-white rounded-lg shadow-md">
                  <div className="card-box align-left">
                    <div className="iconfont-wrapper">
                      <span className="text-4xl text-blue-500 mobi-mbri-change-style"></span>
                    </div>
                    <h5 className="text-xl font-semibold mb-2">Design</h5>
                    <p className="text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <h6 className="text-lg"><a href="#" className="text-blue-500">Read more</a></h6>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-wrapper card3 p-6 bg-white rounded-lg shadow-md">
                  <div className="card-box align-left">
                    <div className="iconfont-wrapper">
                      <span className="text-4xl text-blue-500 mobi-mbri-photo"></span>
                    </div>
                    <h5 className="text-xl font-semibold mb-2">Research</h5>
                    <p className="text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <h6 className="text-lg"><a href="#" className="text-blue-500">Read more</a></h6>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-wrapper card4 p-6 bg-white rounded-lg shadow-md">
                  <div className="card-box align-left">
                    <div className="iconfont-wrapper">
                      <span className="text-4xl text-blue-500 mobi-mbri-rocket"></span>
                    </div>
                    <h5 className="text-xl font-semibold mb-2">Branding</h5>
                    <p className="text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <h6 className="text-lg"><a href="#" className="text-blue-500">Read more</a></h6>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>




        <section className="header2 py-10 my-20" id="header02-g" style={{ background: "aliceblue" }}>
          <div className="container mx-auto">
            <div className="flex justify-center">
              <div className="w-full md:w-8/12 lg:w-8/12">
                <p className="text-center text-3xl md:text-4xl lg:text-5xl font-bold">
                  Explore the research we've done for our great clients.
                </p>
                <p className="text-center mt-4 text-lg md:text-xl lg:text-2xl">
                  <span className="text-secondary">View now.</span>
                </p>
              </div>
            </div>
          </div>
        </section>





        <section className="bg-gray my-20 py-20" id="features06-2" style={{ background: "aliceblue" }}>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="card-wrapper">
                <div className="flex">
                  <div className="w-16 flex justify-center items-center">
                    <span className="text-4xl text-blue-500 mobi-mbri-photo"></span>
                  </div>
                  <div className="flex-1">
                    <h4 className="text-lg font-semibold pb-2">Branding solutions</h4>
                    <p className="text-sm pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce feugiat libero rutrum dui aliquet, quis lacinia eros egestas. Donec finibus felis vel odio tempus, non sagittis augue mattis. Pellentesque habitant morbi tristique.</p>
                    <div className="pb-2">
                      <h5 className="text-base font-semibold">View this service in action:</h5>
                      <h6 className="text-sm">View Case Now</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-wrapper">
                <div className="flex">
                  <div className="w-16 flex justify-center items-center">
                    <span className="text-4xl text-blue-500 mobi-mbri-image-slider"></span>
                  </div>
                  <div className="flex-1">
                    <h4 className="text-lg font-semibold pb-2">Marketing research</h4>
                    <p className="text-sm pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce feugiat libero rutrum dui aliquet, quis lacinia eros egestas. Donec finibus felis vel odio tempus, non sagittis augue mattis. Pellentesque habitant morbi tristique.</p>
                    <div className="pb-2">
                      <h5 className="text-base font-semibold">View this service in action:</h5>
                      <h6 className="text-sm">View Case Now</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-wrapper">
                <div className="flex">
                  <div className="w-16 flex justify-center items-center">
                    <span className="text-4xl text-blue-500 mobi-mbri-change-style"></span>
                  </div>
                  <div className="flex-1">
                    <h4 className="text-lg font-semibold pb-2">Design</h4>
                    <p className="text-sm pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce feugiat libero rutrum dui aliquet, quis lacinia eros egestas. Donec finibus felis vel odio tempus, non sagittis augue mattis. Pellentesque habitant morbi tristique.</p>
                    <div className="pb-2">
                      <h5 className="text-base font-semibold">View this service in action:</h5>
                      <h6 className="text-sm">View Case Now</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-600 text-white my-20 py-20" id="content01-4" >
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-center items-center">
              <div className="md:p-0">
                <h3 className="text-4xl font-bold mb-5">Research<br className="md:hidden" />Together.</h3>
                <p className="text-lg mb-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing. Vivamus blandit tellus euismod erat porta, nec auctor justo aliquet. Donec volutpat auctor tristique. Fusce viverra nunc risus, nec venenatis libero id.
                </p>
                <div className="text-xl">
                  <span className="font-semibold">Discover our services &amp; processes</span>
                </div>
              </div>
              <div className="md:p-0">
                <img className="w-full" src="assets/images/features2.jpg" alt="agrotracdigital" />
              </div>
            </div>
          </div>
        </section>





        <section className="content3 bg-gray-100 py-20 my-20" id="content03-b" style={{ background: "aliceblue" }}>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 justify-center">
              <div className="counter-container col-span-12 md:col-span-2 lg:col-span-10">
                <h3 className="mbr-section-title text-2xl md:text-3xl lg:text-4xl font-bold mb-2">Our project archive</h3>
                <h4 className="mbr-section-subtitle text-lg md:text-xl lg:text-2xl mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                <div className="row icon">
                  <div className="col-lg-2">
                    <p className="mbr-text text-lg md:text-xl lg:text-2xl font-medium">Marketing</p>
                  </div>
                  <div className="col-lg-2">
                    <p className="mbr-text text-lg md:text-xl lg:text-2xl font-medium">2020</p>
                  </div>
                  <div className="col-lg-8">
                    <p className="mbr-text text-lg md:text-xl lg:text-2xl">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam non tellus id elit commodo ullamcorper sed quis mi.&nbsp;</p>
                  </div>
                </div>
                {/* Similar rows for other projects */}
              </div>
            </div>
          </div>
        </section>

        <section className="clients2 bg-gray-200" id="clients02-5">
          {/* Content for the clients section */}
        </section>






        {/* <div className="grid grid-cols-1">
          <div>
            <h1 className="text-2xl py-2">About Us</h1>
            <h1 className="text-md mb-2">Agrotrac Digital is a online platform to Buy/Sell Agri Machinery, Get Service, Parts, Finance, Insurances, News, Reviews and allied services.</h1>


            <h1 className="text-md mb-2">Our vision is to become most trustworthy online platform for Agri Machinery, Technology and allied services. </h1>
            <h1 className="text-ms mb-2">Our mission is to ‘Bring Worlds Best to Indian Farmers  and Offering India’s Best to the World’.</h1>
            <h1>Strategic Values</h1>
            <h1 className="text-md mb-2">Customer Centricity:  Deep insights of customers needs and experiences shall guide all that we do. </h1>
            <h1 className="text-md mb-2">Innovations: Using the power of technology and imagination to deliver solutions to the customers needs </h1>
            <h1 className="text-md mb-2">Excellence: We will strive to achieve and surpass world class quality standards in all that we do </h1>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default PageAbout;
