
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller, useFormContext } from "react-hook-form";


const Rentals = () => {
    const { control, formState: { errors }, setValue, formState, register, watch } = useFormContext();
    const available_for_rental = watch("available_for_rental");
    const HandleRental = (e) => {
        if (e.target.checked == true) {
            setValue('available_for_rental', true);
        }
        if (e.target.checked == false) {
            setValue('available_for_rental', false);
        }
    }
    return (
        <>
            <div className="h-46">
                <div className="flex mt-6 items-center bg-blue-100 w-full py-4 px-2 rounded-sm">
                    <label htmlFor="">Available for Rental &nbsp;&nbsp;&nbsp;</label>
                    <input type="checkbox" {...register('available_for_rental', {})} name='available_for_rental' onChange={(e) => HandleRental(e)} />&nbsp;&nbsp;Yes
                </div>

                {
                    available_for_rental && <>
                        <Controller
                            control={control}
                            name="price_per_unit"
                            rules={{
                                required: "*required",
                                maxLength: {
                                    value: 6,
                                    message: "Rental price cannot be more than 100000"
                                }, pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Please enter a number',
                                }
                            }}
                            render={({ field }) => (
                                <TextField
                                    id="price_per_unit"
                                    label="Price per unit"
                                    variant="filled"
                                    fullWidth
                                    error={Boolean(errors?.price_per_unit)}
                                    helperText={errors.price_per_unit?.message}
                                    margin="normal"
                                    {...field}
                                />
                            )}
                        />

                        <div className="mt-4">
                            <Controller
                                control={control}
                                name="unit"
                                rules={{ required: "*required" }}
                                render={({ field }) => (
                                    <FormControl fullWidth>
                                        <InputLabel id="Unit">Unit</InputLabel>
                                        <Select
                                            labelId="Unit"
                                            id="demo-simple-select-Unit"
                                            label="Unit"
                                            {...field}
                                            error={Boolean(errors?.unit)}
                                            sx={{ width: "100%" }}
                                        >
                                            <MenuItem value='Acer'>Acer</MenuItem>
                                            <MenuItem value='Hour'>Hour</MenuItem>
                                        </Select>
                                        <FormHelperText sx={{ color: "red" }}>{errors.unit?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default Rentals