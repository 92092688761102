// export const getUser = () => {
//   const dataString = sessionStorage.getItem("data")
//   if (dataString) return JSON.stringify(dataString)
//   else return null;
// }
// //
// export const getToken = () => {
//   return sessionStorage.getItem("token") || null;
// }
// //
// export const setdataSession = (token,  data ) => {
//   sessionStorage.setItem('token', token)
//   sessionStorage.setItem('data', JSON.stringify(data))
// }
// //
// export const removedataSession = () => {
//   sessionStorage.removeItem("token")
//   sessionStorage.removeItem("data")
//   window.location.href='/'
// }



export const getUser = () => {
  const dataString = localStorage.getItem("data")
  if (dataString) return JSON.stringify(dataString)
  else return null;
}
//
export const getToken = () => {
  return localStorage.getItem("token") || null;
}
//
export const setdatalocal = (token,  data ) => {
  localStorage.setItem('token', token)
  localStorage.setItem('data', JSON.stringify(data))
}
//
export const removedatalocal = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("data")
  window.location.href='/'
}