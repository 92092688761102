import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { useParams } from "react-router-dom";
import { useFormik } from "formik"
import swal from 'sweetalert';
import * as Yup from 'yup'
import { useState, useEffect } from "react";
import axios from "axios";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const EnquiryFormSchema = Yup.object({
    name: Yup.string().min(2).max(30).required('Please provide your full name'),
    mobile: Yup.string().min(10).max(12).matches(phoneRegExp, 'Phone number is not valid').required(),
    email: Yup.string().email(),
    // tehsil:Yup.string().min(2).max(20).required('Please provide your Tehsil')
})
const DocumentUpload = () => {
    const [document, SetDocument] = useState();
    const { id } = useParams();
    var objdatasting = JSON.parse(sessionStorage.getItem("data"))
    // --::--Setting up Custom Hooks for stateID,districtId, loading etc --::--
    const [state, setState] = useState([])
    const [district, setdistrict] = useState([])
    const [loading, setLoading] = useState(true)
    const [set, Reset] = useState(false)
    const [stateId, setStateId] = useState('')
    const [districtId, setDistrictId] = useState('')
    const regExx = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    const [defaultMail, setDefaultMail] = useState(objdatasting?.email)

    // -----------------------------------------
    // -----Initital State Machine Documnt Form---------
    // -----------------------------------------
    const [formdata, setFormData] = useState({
        name: `${objdatasting?.full_name || ""}`,
        mobile: `${objdatasting?.mobile_number || ""}`,
        email: `${defaultMail === 'NA' ? '' : defaultMail}`,
        product_name: id,
        tehsil: "",
        state: `${stateId}`,
        district: `${districtId}`,
        source_device: "Website",
        inquery_status: "enquiry",
        remarks: "",
        lead_assigned_to: 1
    })


    const getMachineDocument = async () => {
        try {
            let URL = `${process.env.REACT_APP_HOST}`
            let ProductUrl = `${URL}/fleetowners/v1/machine/${id}`
            let tokenStr = `${process.env.REACT_APP_API_KEY}`
            const RESULT = await axios.get(ProductUrl, { headers: { "X-Api-Key": `${tokenStr}` } })
            SetDocument(RESULT.data.data);
        }
        catch (err) {
            console.log(err)
        }
    }

    // ----:: --- Set State ID----:: ---
    const handleState = (e) => {
        const state_id = e.target.value;
        if (state_id !== null) {
            setStateId(state_id)
        }
    }

    // ----:: --- Set District ID----:: ---
    const handleDistrict = (e) => {
        const dis_id = e.target.value;
        if (dis_id !== null) {
            setDistrictId(dis_id)
        }

    }

    useEffect(() => {
        getMachineDocument();
    }, [id]);

    // console.log(document, 'Machine Detailllllllllllllllllllllllll')

    const { touched, values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: formdata,
        validationSchema: EnquiryFormSchema,
        onSubmit: (values) => {
            axios.post(`${process.env.REACT_APP_HOST}/showroom/api/v1/product_inquiry`,
                values, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
                .then(function (response) {
                    if (response.data.status === 208) {
                        swal("info", "Your previous enquiry is already in proces", "success");
                    }
                    if (response.data.status === 200) {
                        swal("Success!", "Thank you, your enquiry has been submitted successfully", "success");
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 3000)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    })

    return (
        <div className='container md:min-h-screen mb-32'>
            <h2 className='mt-8 text-lg font-semibold'>Machine Details</h2>
            <div className="grid md:grid-cols-4 bg-slate-50  p-2 rounded-md">
                <div className='h-40  col-span-2'>
                    <table class="table-auto w-full">
                        <thead>
                            <tr>
                                {/* <th>Song</th>
                                <th>Artist</th> */}
                                {/* <th>Year</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Category</td>
                                <td className='border p-1'>{document?.machine_category_id}</td>
                            </tr>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Subcategory</td>
                                <td className='border p-1'>{document?.sub_category}</td>
                            </tr>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Machine Number</td>
                                <td className='border'>{document?.machine_number}</td>
                            </tr>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Registration City</td>
                                <td className='border'>{document?.registration_city ? document?.registration_city : "Not Provided"}</td>
                            </tr>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Machine Brand</td>
                                <td className='border'>{document?.brand_id}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className='h-40  col-span-2'>
                    <table class="table-auto w-full">
                        <thead>
                            <tr>
                                {/* <th>Song</th>
                                <th>Artist</th> */}
                                {/* <th>Year</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Model Year</td>
                                <td className='border p-1'>{document?.machine_category_id}</td>
                            </tr>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Registraion Year</td>
                                <td className='border p-1'>{document?.reg_no}</td>
                            </tr>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>chassi Number</td>
                                <td className='border'>{document?.chassi_no}</td>
                            </tr>
                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Machine Number</td>
                                <td className='border'>The Eagles</td>
                            </tr>


                            <tr className='w-full'>
                                <td className='border font-semibold p-1'>Percharse Date</td>
                                <td className='border'>{document?.perchase_date}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='my-6'>
                <img src={document?.machine_pic[0]} alt="" className="h-96 object-contain" />
            </div>

            <h2 className='mt-8 text-md font-semibold'>Machine Documents</h2>
            <form className="grid md:grid-cols-1 justify-center bg-transparent md:p-2"
                method="POST" onSubmit={handleSubmit} noValidate autoComplete="off">
                <label className="block">
                    <Label className="Enq_form_text">OwnerShip</Label>
                    <select name="state" id="" className="block w-full border-solid border-1 border-slate-200 rounded Enq_form_text" onChange={(e) => { handleState(e); handleChange(e) }} required>
                        <option value="Select State">OwnerShip</option>
                        <option value='first_owner'>First</option>
                        <option value='second_owner'>second</option>
                        <option value='third_owner'>Third</option>
                        <option value='forth_owner'>Forth</option>
                    </select>
                </label>
                <div className='flex w-full justify-around mt-3'>
                    <h2 className='bg-slate-200 rounded-2xl p-2'>RC</h2>
                    <h2 className='bg-slate-200 rounded-2xl p-2'>invoice</h2>
                    <h2 className='bg-slate-200 rounded-2xl p-2'>insurancey
                    </h2>
                </div>
                <div className='flex w-full'>
                    <label className="h-40 flex bg-slate-200 m-2 w-full">
                        {/* <Label className="Enq_form_text">RC</Label> */}
                        <Input
                            placeholder=""
                            type="file"
                            className="my-1 Enq_form_text rounded-none inline-block w-2/3"
                        />
                        <div className='h-30 relative'>
                            <img src={document?.machine_paper[0]?.rc} className='h-full ' alt="" />
                        </div>
                        {
                            <small className="bg-red-500 text-white  py-1 rounded text-xs">{errors.mobile}</small>
                        }
                    </label>

                    <label className="h-40 flex bg-slate-200 w-full m-2">
                        {/* <Label className="Enq_form_text">RC</Label> */}
                        <Input
                            placeholder=""
                            type="file"
                            className="my-1 Enq_form_text rounded-none inline-block w-2/3"
                        />
                        <div className='h-30 relative'>
                            <img src={document?.machine_paper[0]?.rc} className='h-full w-auto' alt="" />
                        </div>
                        {
                            <small className="bg-red-500 text-white  py-1 rounded text-xs">{errors.mobile}</small>
                        }
                    </label>
                    {/* <Label className="Enq_form_text">RC</Label> */}
                    <label className="h-40 flex bg-slate-200 mt-2 w-full m-2">

                        <Input
                            placeholder=""
                            type="file"
                            className="my-1 Enq_form_text rounded-none inline-block w-2/3"
                        />
                        <div className='h-30 relative'>
                            <img src={document?.machine_paper[0]?.rc} className='h-full w-auto' alt="" />
                        </div>
                        {
                            <small className="bg-red-500 text-white  py-1 rounded text-xs">{errors.mobile}</small>
                        }
                    </label>

                </div>


                <div className="flex justify-between w-2/5 mt-4 items-center">
                    <label htmlFor="">Would you like to take loan ?</label> <div><input type="checkbox" />&nbsp;&nbsp;Yes </div>
                </div>

                <div className="flex justify-between w-2/5 mt-4 items-center">
                    <label htmlFor="">Are you interested in finance  ?</label> <div><input type="checkbox" />&nbsp;&nbsp;Yes </div>
                </div>

                <button
                    type="submit"
                    className="block bg-blue-300 py-3 w-full text-md mt-4 Enq_form_text"
                >
                    Update Now
                </button>
            </form>
        </div>
    )
}

export default DocumentUpload