import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { useParams } from "react-router-dom";
import { useFormik } from "formik"
import Textarea from "shared/Textarea/Textarea";
import swal from 'sweetalert';
import * as Yup from 'yup'
import Button from '@mui/material/Button';
import { initial } from "lodash";
import "./Enq_form.css"
// -------::------ Props Defination for PageContact Page -------::------
export interface PageContactProps {
    className?: string;
    id: number,
    product_name: string;

}

const UsedPageContact: FC<PageContactProps> = ({ className = "", id, product_name }) => {
    const [profileObject, setprofileObject] = useState<any>()
    let dataString = localStorage.getItem("data")
    // --::--Getting User data for Prefilled work--::--
    var objdatasting = JSON.parse(localStorage.getItem("data")!)
    // --::--Setting up Custom Hooks for stateID,districtId, loading etc --::--
    const [state, setState] = useState([])
    const [district, setdistrict] = useState([])
    const [loading, setLoading] = useState(true)
    const [set, Reset] = useState(false)
    const [stateId, setStateId] = useState('')
    const [districtId, setDistrictId] = useState('')
    const regExx = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    const [defaultMail, setDefaultMail] = useState(objdatasting?.email)

    // if (defaultMail === 'NA') setDefaultMail(defaultMail === '')
    // ------------------------------------------------------
    //  -----:----- Enquriy Form Validation Schema -----:----
    //  -----------------------------------------------------
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const EnquiryFormSchema = Yup.object({
        name: Yup.string().min(2).max(30).required('Please provide your full name'),
        mobile: Yup.string().min(10).max(12).matches(phoneRegExp, 'Phone number is not valid').required(),
        email: Yup.string().email(),
        // tehsil:Yup.string().min(2).max(20).required('Please provide your Tehsil')
    })

    // -----------------------------------------
    // -----Initital State Enquriy Form---------
    // -----------------------------------------
    const [formdata, setFormData] = useState({
        name: `${objdatasting?.full_name || ""}`,
        mobile: `${objdatasting?.mobile_number || ""}`,
        email: `${defaultMail === 'NA' ? '' : defaultMail}`,
        machine: id,
        customer: `${objdatasting?.uid}`,
        tehsil: "",
        state: `${stateId}`,
        district: `${districtId}`,
        lead_source: "Website",
        remarks: "",

    })

    // -----------------------------------------------------------------------------------------------------
    // useFormik Hook Take initial state of Enquriy Form and Returns validated values and errors if any
    // -----------------------------------------------------------------------------------------------------
    const { touched, values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: formdata,
        validationSchema: EnquiryFormSchema,
        onSubmit: (values) => {
            axios.post(`${process.env.REACT_APP_HOST}/fleetowners/v1/listing_inquiry`,
                values, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
                .then(function (response: any) {
                    if (response.data.status === 208) {
                        swal("info", "Your previous enquiry is already in proces", "success");
                    }
                    if (response.data.status === 200) {
                        swal("Success!", "Thank you, your enquiry has been submitted successfully", "success");
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 3000)
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                })
        }
    })
    // ------------------------------------------
    // -------API call for State List------------
    // ------------------------------------------
    const getStateteList = async () => {
        const config = {
            headers: {
                "X-Api-Key": `${process.env.REACT_APP_API_KEY}`
            }
        };
        try {
            setLoading(true)
            let URL = `${process.env.REACT_APP_HOST}`
            let ProductUrl = `${URL}/fleetowners/v1/view_states/?key=qwerty`
            const RESULT = await axios.get(ProductUrl, config)
            setState(RESULT.data.data)
            setLoading(false)
            Reset(true)
        }
        catch (err) {
            console.log(err)
        }
    }
    // ------------------------------------------
    // -------API call for District List---------
    // ------------------------------------------
    const getDistictList = async () => {
        const config = {
            headers: {
                "X-Api-Key": `${process.env.REACT_APP_API_KEY}`
            }
        };
        try {
            setLoading(true)
            let URL = `${process.env.REACT_APP_HOST}`
            let ProductUrl = `${URL}/fleetowners/v1/view_districts?state_id=${stateId}&key=qwerty`
            const RESULT = await axios.get(ProductUrl, config)
            setdistrict(RESULT.data.data)
            setLoading(false)
            Reset(true)
        }
        catch (err) {
            console.log(err)
        }
    }
    // ----:: --- Make API call as State ID Changes----:: ---
    useEffect(() => {
        getDistictList();
    }, [stateId])
    // ----:: --- Make API call for State List as Component mount----:: ---
    useEffect(() => {
        getStateteList();
    }, [set])

    // ----:: --- Set State ID----:: ---
    const handleState = (e: any) => {
        const state_id = e.target.value;
        if (state_id !== null) {
            setStateId(state_id)
        }
    }
    // ----:: --- Set District ID----:: ---
    const handleDistrict = (e: any) => {
        const dis_id = e.target.value;
        if (dis_id !== null) {
            setDistrictId(dis_id)
        }

    }

    return (
        <div className="flex justify-center w-full h-full">
            <div
                className={`nc-PageContact overflow-scroll h-full rounded py-2 px-1 md:py-0 md:px-0 hiddenScrollbar ${className}`}
                style={{ width: "100%" }}
                data-nc-id="PageContact">
                <Helmet>
                    <title>AgrotracDigital:{product_name}-enquiry</title>
                </Helmet>
                <div className="">
                    <div className="container-fluid  mx-auto bg-transparent">
                        <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-1 justify-center bg-transparent" >
                            <div>
                                <form className="grid md:grid-cols-1 justify-center bg-transparent md:p-2"
                                    method="POST" onSubmit={handleSubmit} noValidate autoComplete="off">
                                    <label className="block ">
                                        <Label className="Enq_form_text">Name</Label>
                                        <Input
                                            placeholder="Name"
                                            type="text"
                                            className="Enq_form_text rounded-none"
                                            value={values.name}
                                            name="name"
                                            onChange={handleChange}
                                        />
                                        {
                                            <small className="bg-red-500 text-white py-1 rounded text-xs">{errors.name}</small>
                                        }
                                    </label>

                                    <label className="block">
                                        <Label className="Enq_form_text">Mobile</Label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className="my-1 Enq_form_text rounded-none"
                                            value={values.mobile}
                                            name="mobile"
                                            onChange={handleChange}
                                            disabled

                                        />
                                        {
                                            <small className="bg-red-500 text-white  py-1 rounded text-xs">{errors.mobile}</small>
                                        }
                                    </label>

                                    <label className="block Enq_form_text">
                                        <Label className="Enq_form_text">Email Address</Label>
                                        <Input
                                            type="email"
                                            placeholder="Enter email"
                                            className="mb-1 Enq_form_text rounded-none"
                                            value={values.email}
                                            name="email"
                                            onChange={handleChange}
                                        />
                                        {
                                            <small className="bg-red-500 text-white  py-1 rounded text-xs">{errors.email}</small>
                                        }
                                    </label>
                                    <label className="block">
                                        <Label className="Enq_form_text">Product Name</Label>
                                        <Input
                                            type="text"
                                            className="rounded-none"
                                            value={product_name}
                                            name="product"
                                            disabled
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="block">
                                        <Label className="Enq_form_text">State</Label>
                                        <select name="state" id="" className="block w-full border-solid border-1 border-slate-200 rounded Enq_form_text" onChange={(e) => { handleState(e); handleChange(e) }} required>
                                            <option value="Select State">Select State</option>
                                            {
                                                state.map((item: any, index) => {

                                                    return <option key={index} value={item?.state_id}>{item?.state_name}</option>
                                                })
                                            }
                                        </select>
                                    </label>
                                    <label className="block">
                                        <Label className="Enq_form_text">District</Label>
                                        <select name="district" onChange={(e) => { handleDistrict(e); handleChange(e) }}
                                            className="block w-full border-solid border-1 border-slate-200 rounded Enq_form_text" >
                                            <option value="Select State" className="Enq_form_text">Select District</option>
                                            {
                                                district?.map((item: any, index) => {
                                                    return <option key={index} value={item?.district_id} >{item?.district_name}</option>
                                                })
                                            }
                                        </select>
                                    </label>
                                    <label className="block">
                                        <Label className="Enq_form_text">Tehsil</Label>
                                        <Input
                                            placeholder="Tehsil"
                                            type="text"
                                            className="Enq_form_text rounded-none"
                                            value={values.tehsil}
                                            name="tehsil"
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="block mb-2">
                                        <Label className="Enq_form_text">Message</Label>
                                        <Textarea
                                            name="remarks"
                                            placeholder="Enter message here"
                                            className="w-full mt-1 block rounded-none"
                                            rows={4}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="w-full"
                                    >
                                        ENQUIRE NOW
                                    </Button>
                                </form>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER SECTIONS */}
            </div>
        </div>
    );
};

export default UsedPageContact;