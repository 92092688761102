import { Link } from "react-router-dom"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import axios from "axios"
import "./customblog.css"
import { useQuery } from 'react-query'
import ShimmerUI from "components/allProducts/shimmer/ShimmerUI"
import AccountCircle from "@mui/icons-material/AccountCircle"
import { CalendarDaysIcon, CalendarIcon } from "@heroicons/react/24/solid"
const CustomBlog = () => {
    const getBlogList = async () => {
        try {
            let URL = `${process.env.REACT_APP_HOST}`
            let ProductUrl = `${URL}/showroom/api/v1/blog/list`
            let tokenStr = `${process.env.REACT_APP_API_KEY}`
            const RESULT = await axios.get(ProductUrl, { headers: { "X-Api-Key": `${tokenStr}` } })
            return RESULT.data.data
        }
        catch (err) {
            console.log(err)
        }
    }
    const { isLoading, data, isError } = useQuery('getBlogtList', getBlogList, { staleTime: 15 * (60 * 1000), cacheTime: 15 * (60 * 1000) })
    return (
        <>
            <div className="grid grid-cols-4 gap-4">
                {
                    isLoading ?
                        [1, 4, 3, 4].map(() => {
                            return <div className="container px-0 grid align-center grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-1 gap-1"> <ShimmerUI />   </div>
                        })

                        : data.slice(0, 4).map((item, number) => {
                            return <Link to={`/blog-single/${item.slug}`} className="relative block" key={number}>
                                <div class="w-full h-full relative shadow-md ease-in duration-200 rounded-md bg-white">
                                    <div className='blog_img' style={{height: "209px"}}>
                                        <img src={item?.image_url} className='w-full h-full rounded-t-md' alt="" />
                                    </div>
                                    <div className='p-4'>
                                        <h4 className="line-clamp-2 text-ellipsis product_card_font">{item.title}</h4>
                                        <div className='flex my-2 items-center gap-5'>
                                            <div className="flex items-center">
                                                <span style={{width: "16px", height: "16px", display: "inline-block", marginRight: "5px"}}>
                                                    <CalendarDaysIcon fontSize="16" color="#848484" />
                                                </span> 
                                                <span className="inline-block text-[12px] text-[#848484]  pt-[4px]">
                                                    {item.created_at.slice(0, 11)}
                                                </span>
                                            </div>
                                            <div className="flex items-center">
                                                <span style={{width: "16px", height: "16px", alignItems: "flex-start", color: "#848484", display: "inline-block", marginRight: "5px"}}>
                                                    <AccountCircle style={{width: "18px", height: "18px", verticalAlign: "top"}} /> 
                                                </span> 
                                                <span className="inline-block text-[12px] text-[#848484]  pt-[4px]">
                                                    {item.author}
                                                </span>
                                            </div>
                                            
                                        </div>
                                        <h6 className="blog_desc_text line-clamp-4" dangerouslySetInnerHTML={{ __html: item.description }}></h6>
                                        
                                    </div>
                                </div>
                            </Link>
                        })
                }
            </div>
        </>
    )
}

export default CustomBlog