import { useState } from "react";
import buy from "./img/buy.png"
import Service from "./img/Service.png"
// import old2_tractor from "./img/old2_tractor.png"
// import Loan from "./img/loan.png"
// import ins from "./img/ins.png"
import news from "./img/newspaper-folded.png"
// import compare from "./img/compare.png"
import sell from "./img/sell.png"
import rental from "./img/Rental.png"
import parts from "./img/spare-parts.png"
import { Link } from "react-router-dom"
import ModelQuickView2 from '../../components/ModelQuickView2'


const SellBuyMob = () => {
    var objdatasting = JSON.parse(localStorage.getItem("data"))
    const [showModalQuickView, setShowModalQuickView] = useState(false);
    return (
        <>
            <div className="mb-2">
                <h4 className="text-center mb-1 font-light text-xs mt-3">Explore Showroom</h4>
                <div className="flex md:hidden container">
                    <div className="flex The_main">
                        <div className="flex flex-col one  rounded mx-1 cus_height">
                            <Link to="/allProducts" >
                                <img src={buy} alt="" />
                                <span className="text-center font_s">Buy</span>
                            </Link>
                        </div>
                        <div className="flex flex-col one rounded mx-1 cus_height">
                            <Link to="/">
                                <img src={Service} alt="" />
                                <span className="text-center font_s">Service</span>
                            </Link>
                        </div>
                        <div className="flex flex-col one rounded mx-1 cus_height">
                            <Link to="/">
                                <img src={rental} alt="" />
                                <span className="text-center font_s">Rental</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex md:hidden container mt-3">
                    <div class="flex The_main">
                        <div className="flex flex-col one  rounded mx-1 cus_height">
                            {/* <Link to="/">
                                <img src={sell} alt="sell machine icon" />
                                <span className="text-center font_s">Sell</span>
                            </Link> */}

                            {
                                objdatasting?.full_name ?
                                    <div>
                                        <small className="text-xs cursor-pointer hidden lg:block text-slate-500"> </small>
                                        <Link to='/profile'>
                                            <img src={sell} alt="sell machine icon" />
                                            <span className="text-center font_s">Sell</span>
                                        </Link>
                                    </div> : <div ><Link to="#" onClick={() => setShowModalQuickView(true)}>
                                        <img src={sell} alt="sell machine icon" />
                                        <span className="text-center font_s">Sell</span>
                                    </Link></div>
                            }
                        </div>
                        <div className="flex flex-col one  rounded mx-1 cus_height">
                            <Link to="/">
                                <img src={parts} alt="compare icon" />
                                <span className="text-center font_s">Parts</span>
                            </Link>
                        </div>
                        <div className="flex flex-col one rounded mx-1 cus_height">
                            <a href="#agro_blog_sec">
                                <img src={news} alt="news icon" />
                                <span className="text-center font_s">News/Blog</span>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div className="flex md:hidden container mt-3">
                    <div className="flex The_main">
                        <div className="flex flex-col one  rounded mx-1 cus_height">
                            <Link to="/">
                                <img src={ins} alt="" />
                                <span className="text-center font_s">Insurance</span>
                            </Link>
                        </div>
                        <div className="flex flex-col one  rounded mx-1 cus_height">
                            <Link to="/">
                                <img src={valuation} alt="" />
                                <span className="text-center font_s">Valuation</span>
                            </Link>
                        </div>
                        <div className="flex flex-col one rounded mx-1 cus_height">
                            <Link to="/">
                                <img src={vod} alt="" />
                                <span className="text-center font_s">Videos</span>
                            </Link>
                        </div>

                    </div>
                </div> */}
                <div className="container">
                    <button className="w-100 text-center mt-2 Explore_more_btn text-xs py-4 block">Expore More</button>
                </div>
            </div>

            <ModelQuickView2
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
            />
        </>
    )
}

export default SellBuyMob