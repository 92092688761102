import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import React, { Suspense } from 'react';
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import SiteHeader from "containers/SiteHeader";
import PageSearch from "containers/PageSearch";
import ModelQuickView2 from '../components/ModelQuickView2'
import Rentals from "components/rentals/Rentals";
import DocumentUpload from "components/userprofile/DocumentUpload";
import UsedProductDetailPage from "containers/ProductDetailPage/UsedProductDetailPage";
import Sellused from "components/sellused/Sellused";
import BrandDetailPage from "containers/PageHome/brands/BrandDetailPage";
import BuyUsed from "components/buyused/BuyUsed";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import Allproducts from "components/allProducts/Allproducts";
import CircularProgress from '@mui/material/CircularProgress';
// import CustomBlog from "components/CustomBlog/CustomBlog";
import Allblog from "components/CustomBlog/AllBlog";
import PrivacyPoli from "containers/PageAbout/PrivacyPoli";
export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/used-machine-detail/:machine_id/:category/:subcategory/:brand/", component: UsedProductDetailPage },
  //
  { path: "/page-search/:query/", component: PageSearch },
  //
  { path: "/contact", component: PageContact },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: ModelQuickView2 },
  { path: "/subscription", component: PageSubcription },
  { path: "/aboutus", component: PageAbout },
  { path: "/privacy-policy", component: PrivacyPoli },
  //
  { path: "/enquireForm/:id/:product_name", component: PageContact },
  { path: "/rental", component: Rentals },
  { path: "/document-upload/:id/", component: DocumentUpload },
  { path: "/sell-used", component: Sellused },
  { path: "/buy-used", component: BuyUsed },
  { path: "/new-agriculture-machinery-d/:id/:slug/:category/:subcategory/:product_name/", component: ProductDetailPage },
  { path: "/allproducts", component: Allproducts },
  { path: "/all-blog-posts", component: Allblog },
];
//
//
const BlogPage = React.lazy(() => import('containers/BlogPage/BlogPage'))
const BlogSingle = React.lazy(() => import('containers/BlogPage/BlogSingle'))
const Profile = React.lazy(() => import('components/userprofile/Profile'))
const HorizontalLabelPositionBelowStepper_add_machine = React.lazy(() => import('components/userprofile/SetperForm_add'))
const MyVdo = React.lazy(() => import('virtual/MyVdo'))
const aboutus = React.lazy(() => import('containers/PageAbout/PageAbout'))

const Loading = () => <div className="container min-h-screen">
  <CircularProgress className="mt-2" />
</div>


const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />
      <Suspense fallback={<Loading />}>
        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}


          <Route path="/blog" component={BlogPage} />
          <Route path="/blog-single/:slug/" component={BlogSingle} />
          <Route path="/video" component={MyVdo} />
          <Route path="/profile" component={Profile} />
          <Route path="/add-machine" component={HorizontalLabelPositionBelowStepper_add_machine} />
          <Route path="/aboutus" component={aboutus} />
          <Route path="/new-tractor-brand" component={BrandDetailPage} />
          <Route component={Page404} />
        </Switch>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
