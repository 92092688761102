import { FC, useEffect, useId, useRef, useState, useLayoutEffect } from "react";
import axios from "axios";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import { Product } from "data/data";
import { useQuery } from 'react-query'
// import Spinner from "spinner/Spineer";
// import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';
import { SeeAllBtn } from "./BtnLikeIcon/SeeAllBtn";
import CircularProgress from '@mui/material/CircularProgress';
import { config, tld } from "services/globalvariables/globalvariables";
import NextPrev from "shared/NextPrev/NextPrev";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
  machine_cat_id?: any,
  url: string,
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({ className = "", itemClassName = "",
  headingFontClassName,
  headingClassName = 'text-lg',
  heading,
  url
}) => {

  const getProductList = async () => {
    try {
      const RESULT = await axios.get(`${tld}${url}`, config)
      return RESULT.data.data

    }
    catch (err) {
      console.log(err)
    }
  }
  
  const { isLoading, data, isError } = useQuery('getProductList', getProductList, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  var hasNextPrev = true;

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 3 - 1,
        },
        1024: {
          gap: 20,
          perView: 3 - 1,
        },
        768: {
          gap: 20,
          perView: 3 - 2,
        },
        640: {
          gap: 20,
          perView: 2.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [isLoading, sliderRef, UNIQUE_CLASS]);


  return (
    <div className={`nc-SectionSliderProductCard produc_card_bacground${className}`}>
        <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
          <Heading
            className={`${headingClassName} mb-3`}
            fontClass={headingFontClassName}
            hasNextPrev
          >
            {heading || `Popular Machinery`}
          </Heading>
          <div className="relative">
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides pb-1">
                {
                  isLoading ? <div className="flex justify-center items-center h-40 w-full"><CircularProgress /></div> : <>
                    {data.map((item: any, index: number) => {
                      return <li key={index} className={`glide__slide ${itemClassName}`}>
                        <ProductCard data={item} />
                      </li>
                    })}
                  </>
                }
              </ul>
            </div>
            {hasNextPrev  && (
              <div className="customGlideButtonPosition">
                <NextPrev onClickNext={() => { }} onClickPrev={() => { }} />
              </div>
            )}
          </div>
        </div>
        <SeeAllBtn name='View All Latest Machinery' />
      </div>
  );
};

export default SectionSliderProductCard;
