import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

export default function BasicBreadcrumbs(props) {
    const { Home } = props;
    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
               
                <Link underline="hover" color="inherit" href="/" fontSize='.8rem'>
                    {props?.Popular?.Home}
                </Link>

                <Link
                    underline="hover"
                    color="inherit"
                    href="/#"
                    fontSize='.8rem'
                >
                    {props?.new_product}
                </Link>
                {/* <Link
                    underline="hover"
                    color="inherit"
                    href="/#"
                    fontSize='.8rem'
                >
                    {props?.Popular?.Location}
                </Link> */}
                <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    fontSize='.8rem'
                >
                    {props?.product_name}
                </Link>
            </Breadcrumbs>
        </div>
    );
}
