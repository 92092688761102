import React, { FC, useState, useEffect } from "react";
// import LikeButton from "components/LikeButton";
import axios from "axios";
// import Spinner from "spinner/Spineer";
import ModalQuickView from "../../components/ModalQuickView";
import { useParams } from "react-router-dom"
import ModalQuickView2 from "../../components/ModelQuickView2";
import { Link } from "react-router-dom";
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { textAlign } from "@mui/system";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import { useQuery } from 'react-query'
import GradeIcon from '@mui/icons-material/Grade';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import IosShareIcon from '@mui/icons-material/IosShare';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import BasicBreadcrumbs from "components/BreadComb/BreadComb";
import ProductStatus from "components/ProductStatus";

import { config, tld } from "services/globalvariables/globalvariables";
import { singleBlog, singleVdo } from "services/api/allApiHub";
import AccountCircle from "@mui/icons-material/AccountCircle"
import { CalendarDaysIcon, CalendarIcon } from "@heroicons/react/24/solid"
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
} from "react-share";

import DetailPageShimmer from "components/detailPageShimmer/DetailPageShimmer";


import { Icon } from "@mui/material";
import { StarIcon } from "@heroicons/react/24/solid";
import FiveStartIconForRate from "components/FiveStartIconForRate";
import './ProductDetail.css'
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface ProductDetailPageProps { className?: string; singleData?: any; data: any; }
interface ParamTypes { id: any; }
const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const { id } = useParams<ParamTypes>();
  const currentLocation = window.location.href
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  var objdatasting = JSON.parse(localStorage.getItem("data")!)
  const [vdo, Setvdo] = useState('')
  const [image, SetImage] = useState()
  const [blog, SetBlog] = useState([])
  const [hide, setHide] = useState<any>({ isHidden: false })
  const [usedproducts, setUsedproducts] = useState<any>([]);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const accordionHandleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  function toggleHidden() {
    setHide({ isHidden: !hide.isHidden });
  }

  const getBlogList_for_detail = async () => {
    try {
      const RESULT = await axios.get(`${tld}${singleBlog}${id}`, config);
      SetBlog(RESULT.data.data);
    }
    catch (err) {
      console.log(err)
    }
  }

  // console.log(blog, 'detail yewessssss')
  const getvdo = async () => {
    try {
      const RESULT = await axios.get(`${tld}${singleVdo}${id}`, config)
      Setvdo(RESULT?.data?.data[0]?.video_link)
    }
    catch (err) {
      console.log("Server did't respond.");
      console.log(err)
    }
  }

  const getId = () => {
    console.log(currentLocation);
  }

  useEffect(() => {
    getvdo();
    getBlogList_for_detail();
  }, [])


  const getDetailPage = async (id: number) => {
    try {
      const RESULT = await axios.get(`${tld}/showroom/api/v1/product/${id}/detail`, config)
      return RESULT.data
    }
    catch (err) {
      console.log("Server did't respond.")
      console.log(err)
    }
  }

  const { isLoading, data, isError } = useQuery(['productDetail', id], () => getDetailPage(id), { enabled: !!id, staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })


  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderSectionContent = () => {
    return (
      <div className="continer-fluid">
        <Helmet>
          <title>{data?.data[0]?.meta_title}</title>
          <meta name="description" content={data?.data[0]?.meta_description} />
        </Helmet>

        <div>
          <h2 className="mt-12 md:mt-0 text-xl font-medium">
            {data?.data[0]?.product_name}
          </h2>
          <div className="mt-3 flex items-center">
            <h4 className="text-lg font-semibold">
              ₹ <span className="text-[#F17400]">{data?.data[0]?.price.toLocaleString()}.00</span>
            </h4>
            <div className="rating ml-3">
              <FiveStartIconForRate className="text-[#E7D000]" />
            </div>
            <div className="rating-points ml-2 text-[#0DA300] text-sm font-medium"> 4.7</div>
          </div>
        </div>

        {
          <div className="mt-3 text-xs font-roboto text-[#525252] leading-5 line-clamp-4" dangerouslySetInnerHTML={{ __html: data?.data[0]?.description }}>

          </div>
        }
          <div className="my-3">
            <Button variant="outlined" className="customEnquiryButton" onClick={() => setShowModalQuickView(true)}>Enquire now</Button>
            {/* <Button variant="outlined" className="customEnquiryButton" onClick={() => getId()}>Enquire now</Button> */}
          </div>
         { data?.data[0]?.product_name && <div className="font-roboto text-xs">
          <Accordion defaultExpanded expanded={expanded === 'panel1'} onChange={accordionHandleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="panel-heading"
            >
              Technical Specification
            </AccordionSummary>
            <AccordionDetails>
              {TechSpec()}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={accordionHandleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              className="panel-heading"
            >
              Description
            </AccordionSummary>
            <AccordionDetails>
              <div className="mt-1" >
                {<div dangerouslySetInnerHTML={{ __html: data?.data[0]?.description }}></div>}

              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={accordionHandleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              className="panel-heading"
            >
              Advantages
            </AccordionSummary>
            <AccordionDetails>
              {data?.data[0]?.bullet}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={accordionHandleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
              className="panel-heading"
            >
              Brochure
            </AccordionSummary>
            <AccordionDetails>
              <div className="card">
                <a href={data?.data[0]?.document_url} target='_blank' className="text-xs block font-bold text-red"><PictureAsPdfIcon style={{color: "#red" }} /></a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={accordionHandleChange('panel5')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5-content"
              id="panel5-header"
              className="panel-heading"
            >
              Videos
            </AccordionSummary>
            <AccordionDetails>
            {
                          vdo ? <div className="mt-6">
                            <iframe className="w-full md:w-2/5 h-60" src={vdo} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                          </div> : <h2 className="mt-6 text-xl font-light ">No Video Available..</h2>
                        }
            </AccordionDetails>
          </Accordion>
        </div>
        }
      </div>
    );
  };


  const TechSpec = () => {
    return (
      <table className="border w-full text-sm ">
        <tbody>
        {
          data?.data[0]?.attribute_group?.map((item: any, key: number) => {
            return <>
                {item?.product_attribute?.map((item: any, key: number) => {
                  return <tr>
                    <td className="border p-2" >{item.attribute_name}</td>
                    <td className="border p-2">{item.value}</td>
                  </tr>
                })}
              </>
            
          })
        }
        </tbody>
      </table>
    );
  };



  const TechSpec_short = () => {
    return (
      <div className="">
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
          <table className="table-fixed">
            {
              data?.data[0]?.attribute_group?.slice(0, 5).map((item: any, key: number) => {
                return <tbody>
                  <tr>
                    {item.product_attribute.map((item: any, key: number) => {
                      if (item.key_specification === 'yes') {
                        return <tbody className="flex justify-start w-full">
                          <tr className="w-full text-xs flex key_spec_font">
                            <td className="td_width2 border" >{item.attribute_name}</td>
                            <td className="border td_width2">{item.value}</td>
                          </tr>
                        </tbody>
                      }
                    })}
                  </tr>
                </tbody>
              })
            }
          </table>
        </div>
      </div>
    );
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const HomePageData = {
    Home: "Home",
    product_name: `${data?.data[0]?.product_name}`,
    new_product: "New Products"
  }




  return (
    <>
      {
          isLoading ? <DetailPageShimmer/> : <>
            <div className={`nc-ProductDetailPage ${className}`}>
              {/* MAIn */}
              <main className="container mt-5 lg:mt-2">
                <div className="lg:flex">
                  {/* CONTENT */}
                  <div className="w-full lg:w-[50%] relative">
                    {/* {
                      data?.data[0]?.product_type === 'new' ?
                        <div className="inline-block font-light absolute   md:top-0  md:left-0 text-xs text-white bg-green-600 p-1 md:p-2 z-10 px-3 font-semibold">New</div> : null
                    } */}

                    <ProductStatus status="New in" />

                    {/* HEADING */}
                    <div className="relative newMainImageAni">
                      <div className="w-full md:h-70">
                        <NcImage
                          src={image || data?.data[0]?.image_url || usedproducts?.machine_pic}
                          className="w-full h-96 object-contain border"
                          title={data?.data[0]?.product_name}
                          alt={data?.data[0]?.product_name}
                        />
                      </div>
                      {/* SHARE ICON */}
                      < IosShareIcon className={`absolute right-6  top-3 cursor-pointer`} onClick={toggleHidden} />

                      <div className={`absolute right-5  top-11 cursor-pointer w-7 ease-in duration-300`} style={{ visibility: hide.isHidden ? "hidden" : "visible" }}>
                        <FacebookShareButton
                          url={currentLocation}
                        >
                          <FacebookIcon size={34} round={true} className="mt-2" />
                        </FacebookShareButton >

                        <WhatsappShareButton
                          url={currentLocation}

                        >
                          <WhatsappIcon size={34} round={true} className="" />
                        </WhatsappShareButton >


                        <TwitterShareButton
                          url={currentLocation}
                        >
                          <TwitterIcon size={34} round={true} className="mt-2" />
                        </TwitterShareButton >


                        <LinkedinShareButton
                          url={currentLocation}
                        >
                          <LinkedinIcon size={34} round={true} className="mt-2" />
                        </LinkedinShareButton >



                        {/* <PinterestShareButton
                          url={currentLocation}
                        >
                          <PinterestIcon size={34} round={true} className="mt-2" />
                        </PinterestShareButton > */}


                      </div>
                    </div>
                    {
                      objdatasting?.full_name ?
                        <ModalQuickView
                          id={data?.data[0]?.id}
                          product_name={data?.data[0]?.product_name}
                          show={showModalQuickView}
                          onCloseModalQuickView={() => setShowModalQuickView(false)}
                        />
                        :
                        <ModalQuickView2
                          show={showModalQuickView}
                          onCloseModalQuickView={() => setShowModalQuickView(false)}
                        />
                    }

                    <div className="flex mt-3  absolute xl:mt-8 justify-between  md:h-20 w-full lg:w-[100%] overflow-x-auto">
                      <img src={data?.data[0]?.image_url} onClick={() => SetImage(data?.data[0]?.image_url)} className="object-contain shadow-lg cursor-pointer border h-14 w-14 md:h-20 md:w-20" alt="" />
                      {
                        data?.data[0]?.images?.map((item: any, index: number) => {
                          return <img
                            src={item.image_url}
                            className="object-contain shadow-lg cursor-pointer border h-14 w-14  md:h-20 md:w-20"
                            alt="multi image"
                            onClick={() => SetImage(item.image_url)}
                          />
                        })
                      }

                    </div>

                  </div>

                  {/* SIDEBAR */}
                  <div className="w-full lg:w-[50%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                  <div className="my-4 text-xs">
                    <BasicBreadcrumbs Popular={HomePageData} product_name={data?.data[0]?.product_name} new_product="New Machinery" />
                  </div>
                    {renderSectionContent()}
                  </div>

                </div>


                {
                    usedproducts?.machine_id && <table className="table-auto w-full border p-2">
                      <thead>

                      </thead>
                      <tbody>
                        <tr>
                          <td className="bg-slate-100 my-1 border">Name</td>
                          <td className="bg-slate-100 my-1 border">{usedproducts?.machine_category_id}</td>
                          {/* <td><b>Boom Spryer</b></td> */}

                        </tr>
                        <tr>
                          <td className="bg-slate-100 my-1 border">Category</td>
                          <td className="bg-slate-100 my-1 border">{usedproducts?.machine_category_id}</td>

                        </tr>
                        <tr>
                          <td className="bg-slate-100 my-1 border">Subcategory</td>
                          <td className="bg-slate-100 my-1 border">{usedproducts?.sub_category}</td>

                        </tr>
                        <tr>
                          <td className="bg-slate-100 my-1 border">Brand</td>
                          <td className="bg-slate-100 my-1 border">{usedproducts?.brand_id}</td>

                        </tr>



                      </tbody>
                    </table>
                  }
              </main>
              {/* MODAL VIEW ALL REVIEW */}
            </div>
          </>
        }
      <div className="container my-20">
        <h2 className="text-lg font-medium text-[#1550A1] mb-4">Related Blogs</h2>
        <div className="container px-0 grid align-center grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-1 gap-4">
          {
            isLoading ? <h2>wait..</h2> : blog.slice(0, 4).map((item: any, number: any) => {
              return <Link to={`/blog-single/${item.slug}`} className="relative block" key={number}>
                <div className="grid grid-cols-4 gap-2.5">
                <div className="w-full h-full relative shadow-md ease-in duration-200 rounded-md bg-white">
                    <div className='blog_img' style={{height: "209px"}}>
                        <img src={item?.image_url} className='w-full h-full rounded-t-md' alt="" />
                    </div>
                    <div className='p-4'>
                        <h4 className="line-clamp-2 text-ellipsis product_card_font">{item.title}</h4>
                        <div className='flex my-2 items-center gap-5'>
                            <div className="flex items-center">
                                <span style={{width: "16px", height: "16px", display: "inline-block", marginRight: "5px"}}>
                                    <CalendarDaysIcon fontSize="16" color="#848484" />
                                </span> 
                                <span className="inline-block text-[12px] text-[#848484]  pt-[4px]">
                                    {item.created_at.slice(0, 11)}
                                </span>
                            </div>
                            <div className="flex items-center">
                                <span style={{width: "16px", height: "16px", alignItems: "flex-start", color: "#848484", display: "inline-block", marginRight: "5px"}}>
                                    <AccountCircle style={{width: "18px", height: "18px", verticalAlign: "top"}} /> 
                                </span> 
                                <span className="inline-block text-[12px] text-[#848484]  pt-[4px]">
                                    {item.author}
                                </span>
                            </div>
                            
                        </div>
                        <h6 className="blog_desc_text line-clamp-4" dangerouslySetInnerHTML={{ __html: item.description }}></h6>
                        
                    </div>
                </div>
                </div>
                
              </Link>
            })
          }
        </div>
      </div>
    </>
  );
};

export default ProductDetailPage;

