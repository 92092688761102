import React, { FC, useState } from "react";
import axios from "axios";
import ModalQuickViewUsed from "components/ModelQuickViewUsed";
import { useParams } from "react-router-dom"
import ModalQuickView2 from "../../components/ModelQuickView2";
import { textAlign } from "@mui/system";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import { useQuery } from 'react-query'
import IosShareIcon from '@mui/icons-material/IosShare';
import ProductStatus from "components/ProductStatus";
// import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
} from "react-share";
import { config, tld } from "services/globalvariables/globalvariables";
import './ProductDetail.css'


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface ProductDetailPageProps { className?: string; singleData?: any; data: any; }
interface ParamTypes { machine_id: any; }
const UsedProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const { machine_id } = useParams<ParamTypes>();
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  var objdatasting = JSON.parse(localStorage.getItem("data")!)
  const [image, SetImage] = useState()
  const [hide, setHide] = useState<any>({ isHidden: false })


  function toggleHidden() {
    setHide({ isHidden: !hide.isHidden });
  }


  const getDetailPageUsed = async (id: number) => {
    try { return (await axios.get(`${tld}/fleetowners/v1/machine/${id}`, config)).data }
    catch (err) { console.log(err) }
  }
  const { isLoading, data } = useQuery(['productDetail', machine_id], () => getDetailPageUsed(machine_id), { enabled: !!machine_id, staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })
  const currentLocation = window.location.href
  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8 continer-fluid mt-6" >
        <Helmet>
          <title>{data?.data[0]?.meta_title}</title>
          <meta name="description" content={data?.data[0]?.meta_description} />
        </Helmet>

        {/* // ****---------------------------------------------------------------***
  //              Product Detail Page Side View
  // ****---------------------------------------------------------------***  */}
        <div  >
          <h2 className="mt-12 md:mt-0  text-xl sm:text-2xl font-semibold">
            {data?.data?.machine_model}
          </h2>
          <div className="flex items-center space-x-4 sm:space-x-5">
          </div>
        </div>

        <div className="w-full flex justify-between">


        </div>
        {/* ---------- 5 ----------  */}
        <div className="flex items-center justify-between">
          <div className="font-semibold">{data?.data?.brand}</div>
        </div>
        <div className="">
          {
            <table className="table-auto w-full  p-2">
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td className="bg-slate-50 my-1 border text-white-400 text-sm p-1">Name</td>
                  <td className="bg-slate-50 my-1 border text-white-400 text-sm p-1">{data.data.machine_category_id}</td>
                </tr>
                <tr>
                  <td className="bg-slate-100 my-1 border text-white-400 text-sm p-1">Category</td>
                  <td className="bg-slate-100 my-1 border text-white-400 text-sm p-1">{data.data?.machine_category_id}</td>

                </tr>
                <tr>
                  <td className="bg-slate-50 my-1 border text-white-400 text-sm p-1">Subcategory</td>
                  <td className="bg-slate-50 my-1 border text-white-400 text-sm p-1">{data.data?.sub_category}</td>

                </tr>
                <tr>
                  <td className="bg-slate-100 my-1 border text-white-400 text-sm p-1">Brand</td>
                  <td className="bg-slate-100 my-1 border text-white-400 text-sm p-1">{data.data?.brand_id}</td>

                </tr>

                <tr>
                  <td className="bg-slate-100 my-1 border text-white-400 text-sm p-1">Location/Area</td>
                  <td className="bg-slate-100 my-1 border text-white-400 text-sm p-1">{data.data?.territory}</td>
                </tr>
              </tbody>
            </table>
          }
        </div>
        <div className="text-white-400 text-sm p-1">
          Asking Price. <strong>{data?.data?.asking_price}</strong>/-
        </div>

        <Button variant="contained" className="w-full" onClick={() => setShowModalQuickView(true)}>Enquire now</Button>
      </div>
    );
  };

  // ****---------------------------------------------------------------***
  //              Product Descripton Saprate
  // ****---------------------------------------------------------------***
  return (
    <>
      <div className="min-h-screen">

        {
          isLoading ? null : <>
            <div className={`nc-ProductDetailPage ${className}`}>
              {/* MAIn */}
              <main className="container mt-5 lg:mt-2">
                <div className="lg:flex">
                  {/* CONTENT */}
                  <div className="w-full lg:w-[55%] relative md:mt-4">
                    <ProductStatus status="Available" />
                    {/* HEADING */}
                    <div className="relative ">
                      <div className="">
                        <NcImage
                          src={image || data?.data?.machine_pic[0]}
                          className="h-96 w-full object-contain border"
                          title={data?.data[0]?.product_name}
                          alt={data?.data[0]?.product_name}
                        />
                      </div>
                      {/* SHARE ICON */}
                      < IosShareIcon className="absolute right-6  top-3 cursor-pointer" onClick={toggleHidden} />

                      <div className={`absolute right-5  top-11 cursor-pointer w-7 ease-in duration-300`} style={{ visibility: hide.isHidden ? "hidden" : "visible" }} >
                        <FacebookShareButton
                          url={currentLocation}
                        >
                          <FacebookIcon size={34} round={true} className="mt-2" />
                        </FacebookShareButton >

                        <WhatsappShareButton
                          url={currentLocation}

                        >
                          <WhatsappIcon size={34} round={true} className="mt-2" />
                        </WhatsappShareButton >


                        <TwitterShareButton
                          url={currentLocation}
                        >
                          <TwitterIcon size={34} round={true} className="mt-2" />
                        </TwitterShareButton >


                        <LinkedinShareButton
                          url={currentLocation}
                        >
                          <LinkedinIcon size={34} round={true} className="mt-2" />
                        </LinkedinShareButton >



                        {/* <PinterestShareButton
                          url={currentLocation}
                        >
                          <PinterestIcon size={34} round={true} className="mt-2" />
                        </PinterestShareButton > */}


                      </div>
                    </div>
                    {
                      objdatasting?.full_name ?
                        <ModalQuickViewUsed
                          id={data?.data?.machine_id}
                          product_name={data?.data?.machine_model}
                          show={showModalQuickView}
                          onCloseModalQuickView={() => setShowModalQuickView(false)}
                        />
                        :
                        <ModalQuickView2
                          show={showModalQuickView}
                          onCloseModalQuickView={() => setShowModalQuickView(false)}
                        />
                    }

                    <div className="flex mt-3  absolute xl:mt-8 justify-between  md:h-20 w-full lg:w-[100%] overflow-x-auto">
                      {
                        data?.data?.machine_pic?.map((item: any, index: number) => {
                          return <img
                            src={item}
                            className="object-contain shadow-lg cursor-pointer border h-14 w-14  md:h-20 md:w-20"
                            alt="multi image"
                            onClick={() => SetImage(item)}
                          />
                        })
                      }
                    </div>
                  </div>
                  {/* SIDEBAR */}
                  <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                    {renderSectionContent()}
                  </div>

                </div>
                {/* DETAIL AND REVIEW */}
                <div className="mt-12 sm:mt-36 space-y-10 sm:space-y-16 mb-40">
                </div>
              </main>
              {/* MODAL VIEW ALL REVIEW */}
            </div>
          </>
        }
        <a href="https://api.whatsapp.com/send?phone=7240288888&text=Get Best Deals on Agriculture Machinery." className="float2" target="_blank">
          <i className="fa fa-whatsapp my-float2"></i>
        </a>
      </div>
    </>
  );
};

export default UsedProductDetailPage;










