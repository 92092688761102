import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { DevicePhoneMobileIcon, PhoneArrowDownLeftIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { EmailIcon } from "react-share";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Explore Us",
    menus: [
      { href: "/aboutus", label: "About us" },
      { href: "#", label: "Partner with us" },
      { href: "#", label: "Career with us" },

    ],
  },
  {
    id: "1",
    title: "Terms of Us",
    menus: [

      { href: "/privacy-policy", label: "Privacy Policy" },
      { href: "https://webadmin.traxi.in/fleetowners/v1/terms-and-conditions/", label: "Terms of services" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "#", label: "Advertise with Us" },
      { href: "#", label: "Prime Membership" },
      { href: "#", label: "Guest Post" },

    ],
  },

  {
    id: "4",
    title: "Follow Us",
    menus: [
      { href: "https://www.facebook.com/agrotracdigital", label: "Facebook" },
      { href: "https://www.instagram.com/agrotracdigital", label: "Instagram" },
      { href: "https://www.youtube.com/@agrotracdigital", label: "Youtube" },
    ],
  },

  {
    id: "5",
    title: "Contact Us",
    menus: [
      { href: "#", label: "+91 72402 88888" },
      { href: "https://www.agrotracdigital.com/", label: "admin@agrotracdigital.com" },
      { href: "", label: "FAQ's" },
    ],
  },



];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-xs">
        <h2 className="font-semibold text-white text-sm">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4 text-xs">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-white"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="bg-[#313131]">
      <div className="container py-5">
        <div className="grid grid-cols-4 gap-4">
          {/* {widgetMenus.map(renderWidgetMenuItem)} */}
          <div className="">
            <h2 className="font-semibold text-white text-sm">
              Explore Us
            </h2>
            <ul className="mt-5 space-y-4 text-xs">
              <li>
                <a
                  href="/aboutus"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  Partner with us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  Career with us
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h2 className="font-semibold text-white text-sm">
              Terms of Us
            </h2>
            <ul className="mt-5 space-y-4 text-xs">
              <li>
                <a
                  href="#"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  Terms of services
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h2 className="font-semibold text-white text-sm">
              Resources
            </h2>
            <ul className="mt-5 space-y-4 text-xs">
              <li>
                <a
                  href="#"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  Advertise with Us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  Prime Membership
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  Guest Post
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-white"
                  rel="noopener noreferrer"
                >
                  FAQ's
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <h2 className="font-semibold text-white text-sm">
              Follow Us
            </h2>
            <ul className="mt-5 space-x-3 flex text-xs">
              <li>
                <a
                  href="https://www.facebook.com/agrotracdigital"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/facebook.png" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/agrotracdigital"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/instagram.png" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@agrotracdigital"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/youtube.png" />
                </a>
              </li>
            </ul>
            <h2 className="font-semibold text-white mt-5 text-sm">
              Contact Us
            </h2>
            <ul className="mt-5 space-y-4 text-xs">
              <li>
                <a
                  href="/aboutus"
                  className="text-white flex items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="w-[20px] h-[20px] inline-block mr-2">
                    <PhoneIcon />
                  </span>
                  <span>+91 72402 88888</span>
                </a>
              </li>
              <li>
                <a
                  href="mailto:admin@agrotracdigital.com"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="w-[20px] h-[20px] inline-block mr-2 font-bold text-[20px]">
                    @
                  </span>
                  <span>admin@agrotracdigital.com</span>
                  
                </a>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Footer;
