import React, { FC, useEffect, useId, useRef, useState } from "react";
import axios from "axios"
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import { Product, PRODUCTS } from "data/data";
import { useQuery } from 'react-query'
// import {UserService} from "../service/GetAlldata"
// import Spinner from "spinner/Spineer";
import CircularProgress from '@mui/material/CircularProgress';
export interface SectionSliderCustomProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
  machine_id?: string
  sub_category_id?: string
}
const SectionSliderProductCard: FC<SectionSliderCustomProps> = ({ className = "", itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  machine_id,
  sub_category_id
}) => {
  const [products, setProdcuts] = useState([])
  const [loading, setLoading] = useState(true)
  const [set, Reset] = useState(false)

  const getProductList = async (machine_id: any) => {
    const config = { headers: { "X-Api-Key": `${process.env.REACT_APP_API_KEY}` } };
    try {
      let URL = `${process.env.REACT_APP_HOST}`
      let ProductUrl = `${URL}/showroom/api/v1/product_list/?q=${machine_id}&filter_by_category=machine_category_id`
      //  let ProductUrl = `${URL}/showroom/api/v1/product_list/?q=${2}&filter_by=id` 
      const RESULT = await axios.get(ProductUrl, config)
      Reset(true)
      return RESULT.data.data
    }
    catch (err) {
      console.log(err)
    }
  }
  const { isLoading, data, isError } = useQuery(['getSpryCat', machine_id], () => getProductList(machine_id), { enabled: !!machine_id, staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 2.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS, isLoading, machine_id]);


  return (
    <div className={`nc-SectionSliderProductCard ${className} produc_card_bacground `}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          hasNextPrev
        >
          {heading || ' '}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {
              isLoading ? <div className="flex justify-center items-center h-40 w-full"><CircularProgress /></div> : <>
                {data?.map((item: any, index: number) => {
                  return <li key={index} className={`glide__slide ${itemClassName}`}>
                    <ProductCard data={item} />
                  </li>
                })}
              </>
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderProductCard;
