import CardCategory1 from "components/CardCategories/CardCategory1";
import CardCategory4 from "components/CardCategories/CardCategory4";
import Heading from "components/Heading/Heading";
import NavItem2 from "components/NavItem2";
import React, { useEffect, useState } from "react";
import Nav from "shared/Nav/Nav";
import axios from "axios";
import SectionSliderCustom from "../../components/SectionSliderCustom"
import { tld, config } from "services/globalvariables/globalvariables";
import { old_api_sub_category, allCategories } from "services/api/allApiHub";

const SectionGridMoreExplore = ({
  className = "",
  boxCard = "box1",
  gridClassName = "grid-cols-2 md:grid-cols-2 xl:grid-cols-4",
}) => {
  const [tabActive, setTabActive] = React.useState("Soil Preparation");
  const [products, setProdcuts] = useState([])
  const [filtereddata, Setfiltereddata] = useState([])
  const [machine_id, setmachine_id] = useState('7b53c7ff-159f-4380-8fad-2a60e257b6b6')
  const [set, Reset] = useState(false)
  const [loading, setLoading] = useState(true)
  const [subCategory, setSubCategory] = useState([])
  const [subcatToken, SetSubCatToken] = useState('7b53c7ff-159f-4380-8fad-2a60e257b6b6')


  const getSubCategory = async () => {
    try {
      setLoading(true)
      const RESULT = await axios.get(`${tld}${old_api_sub_category}${subcatToken}`, config)
      setSubCategory(await RESULT.data.data)
      setLoading(false)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getSubCategory();
  }, [subcatToken])

  const getCategory = async () => {
    try {
      setLoading(true)
      const RESULT = await axios.get(`${tld}${allCategories}`, config)
      setProdcuts(await RESULT.data.data)
      setLoading(false)
      const data_list = products.filter((i) => i.sub_category.length > 0)
      Setfiltereddata(data_list)
      Reset(true)
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getCategory();
  }, [set,subcatToken])


  const renderCard = (item) => {
    switch (boxCard) {
      case "box1":
        return (
          <CardCategory1
            name={item?.sub_category}
            featuredImage={item?.image_url}
            key={item?.id}
            id={item.id}
          />
        );
      case "box4":
        return (
          <CardCategory4
            key={item.id}
            name={item?.sub_category}
            featuredImage={item?.image_url}
          />
        );
      default:
        return (
          <CardCategory4
            name={item.sub_category}
            featuredImage={item.image_url}
            key={item?.id}
            color={item?.color}
          />
        );
    }
  };

  const renderHeading = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="produc_card_bacground">
        <Heading
          className="mb-3"
          >
            Search By Category
          </Heading>
        </div>
        <Nav
          className="overflow-x-auto hiddenScrollbar border-b-4"
          containerClassName=""
        >
          {filtereddata?.map((item, index) => (
            <NavItem2
              key={index}
              isActive={tabActive == item?.machine_category_name}
              onClick={(e) => {
                setTabActive(item?.machine_category_name)
                SetSubCatToken(item?.sub_category[0]?.category)
                setmachine_id(item.machine_category_id)
                getSubCategory()
              }}
            >
              <div className="flex items-center justify-center">
                <span
                  className="inline-block"
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                ></span>
                <span>{item.machine_category_name}</span>
              </div>
            </NavItem2>
          ))}
        </Nav>
      </div>
    );
  };

  return (
    <div className="container">
      <div
        className={`nc-SectionGridMoreExplore mt-6 md:mt-0  ${className}`}
        data-nc-id="SectionGridMoreExplore"
      >
        {renderHeading()}
        {
          // <div className={`produc_card_bacground flex ${gridClassName}`}>
          //   {subCategory.map((item) => renderCard(item))}
          // </div>
        }
        <SectionSliderCustom machine_id={machine_id} />
      </div>
    </div>
  );
};

export default SectionGridMoreExplore;




