import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { Product } from "data/data";
import React, { FC } from "react";
import IconDiscount from "./IconDiscount";

interface Props {
  status: Product["status"];
  className?: string;
}

const ProductStatus: FC<Props> = ({
  status,
  className = "absolute top-0 left-0 px-2.5 py-1.5 text-xs",
}) => {
  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES = `${className}`;
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5 text-[#F17400]" />
          {/* <span className="ml-1 leading-none font-semibold  text-xs x-40">{status}</span> */}
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }

    if (status === "Available") {
      return (
        <div className={CLASSES}>
          {/* <ClockIcon className="w-3.5 h-3.5" /> */}
          <span className="ml-1 leading-none font-semibold text-xs">{status}</span>
        </div>
      );
    }
    return null;
  };

  return renderStatus();
};

export default ProductStatus;





