import { useState } from 'react';
import axios from 'axios';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import swal from 'sweetalert';
import MachineCategory from './stepper-comp/MachineCategory';
import MachineInformation from './stepper-comp/MachineInformation';
import MachinePhotos from './stepper-comp/MachinePhotos';
import Rentals from './stepper-comp/Rentals';
import Sell from './stepper-comp/Sell';
import { useForm, FormProvider } from "react-hook-form";

function getSteps() {
    return [
        "Machine Category",
        "Machine Information",
        "Machine Photos",
        "Rentals",
        "Sell",
    ];
}



function getStepContent(step) {
    switch (step) {
        case 0:
            return <MachineCategory />;
        case 1:
            return <MachineInformation />;
        case 2:
            return <MachinePhotos  />;
        case 3:
            return <Rentals />;
        case 4:
            return <Sell />;
        default:
            return "unknown step";
    }
}

const SetperForm_add = ({ setHide, setHideHideMachine, setAddMachine, Reset }) => {
    // const [required, setRequired] = useState(true);
    var objdatasting = JSON.parse(localStorage.getItem("data"))
    const methods = useForm({
        defaultValues: {
            machine_number: "",
            machine_model: "",
            chassi_no: null,
            engine_no: null,
            reg_no: null,
            perchase_date: null,
            machine_pic: [
            ],
            // registration_year: 2015,
            kilometres_driven: "",
            seller_type: null,
            owner: "",
            // insurance: null,
            registration_city: "",
            notes: "",
            machine_commission: 7.5,
            created_at: "2023-03-18 22:46:02",
            updated_at: "2023-03-18 22:46:02",
            is_default: false,
            is_verified: false,
            available_for_rental: false,
            unit: null,
            price_per_unit: null,
            available_for_sell: false,
            entityid: 285999155,
            uid: objdatasting?.uid,
            machine_category_id: "",
            sub_category: null,
            brand_id: "",
            service_id: "5",
            added_by: objdatasting?.uid,
            employee: null,
            territory: null,
            asking_price: null,
            machine_paper: [
                {
                    ownership: "first_owner",
                    financier: "no",
                    finance_doc: "",
                    invoice: "",
                    rc: "",
                    insurance: "no",
                    insurance_doc: "",
                    loan: "no"
                }
            ],
            crop_id: [
                "",
                ""
            ]
        },
    });
    const [activeStep, setActiveStep] = useState(0);
    const [skippedSteps, setSkippedSteps] = useState([]);
    const steps = getSteps();
    const isStepOptional = (step) => {
        return false
    };
    const isStepFalied = () => {
        return Boolean(Object.keys(methods.formState.errors).length);
    };
    const isStepSkipped = (step) => {
        return skippedSteps.includes(step);
    };

    const handleNext = (data) => {
        // console.log(data, 'data has to be submitted');
        if (activeStep == steps.length - 1) {
            axios.post(`${process.env.REACT_APP_HOST}/fleetowners/v1/machine`,
                data, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
                .then(function (response) {
                    if (response.status === 200) {
                        swal("Success!", "Thank you, Machine added successfully! And your machine is under verification process.", "success");
                        Reset(true);
                        setActiveStep(0);
                        setHide('block');
                        setHideHideMachine('hidden');
                        setAddMachine('hidden');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 400) {
                        alert('Something went worng ! Please contact your Admin.');
                    }
                    if (error.response.status === 500) {
                        alert('Something went worng ! Please contact your Admin.');
                    }

                    if (error.response.status === 208) {
                        alert('You have already add this machine.');
                    }
                })
        } else {
            setActiveStep(activeStep + 1);
            setSkippedSteps(
                skippedSteps.filter((skipItem) => skipItem !== activeStep)
            );
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <div>
          <div className='overflow-x-scroll md:overflow-x-auto'>
          <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step, index) => {
                    const labelProps = {};
                    const stepProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography
                                variant="caption"
                                align="center"
                                style={{ display: "block" }}
                            >
                                optional
                            </Typography>
                        );
                    }
                    if (isStepFalied() && activeStep == index) {
                        labelProps.error = true;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step {...stepProps} key={index} className='mt-5'>
                            <StepLabel {...labelProps}>{step}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
          </div>

            {activeStep === steps.length ? (
                <Typography variant="h3" align="center">
                    Thank You
                </Typography>
            ) : (
                <>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(handleNext)}>
                            {getStepContent(activeStep)}

                            <div className="flex justify-between w-full mt-6">
                                <Button
                                    variant="contained"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    back
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    // onClick={handleNext}
                                    type="submit"
                                >
                                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </>
            )}
        </div>
    );
};

export default SetperForm_add;