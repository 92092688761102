import React, { FC, useEffect, useState } from "react";
import MainNav2Logged from "./MainNav2Logged";
// import './desktop_nav.css'
import "./desktop_nav.css"
export interface HeaderLoggedProps { }

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos < prevScrollPos) {
        setIsSticky(true);
      } else if (currentScrollPos > prevScrollPos) {
        setIsSticky(false);
      }
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky, prevScrollPos]);


  return (
    <div className={` nc-HeaderLogged  top-0  w-full transition-shadow z-40  ${isSticky ? 'sticky transition-shadow' : null} `}>
      <MainNav2Logged />
    </div>
  );
};

export default HeaderLogged;
