import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import ProductCard from "components/ProductCard";
import { useParams } from "react-router-dom"
// import { Link } from "react-router-dom";
import axios from "axios";
// import Spinner from "spinner/Spineer";
// import no_result from '../images/no-results.png'
// import { dividerClasses } from "@mui/material";
// import CircularProgress from '@mui/material/CircularProgress';
// import not_exit_nobg from 'images/not-exit-nobg.png'
import { config, tld } from "services/globalvariables/globalvariables";
import ShimmerUI from "components/allProducts/shimmer/ShimmerUI";
export interface PageSearchProps { className?: string; }
interface ParamTypes { query: string }
const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
  const { query } = useParams<ParamTypes>();
  const [products, setProdcuts] = useState<any>([])
  const [loading, setLoading] = useState<boolean>();
  const [avail, setAvail] = useState<boolean>(false)

  const getSearchedProductList = async () => {
    try {
      setLoading(true)
      const QueryString = query;
      const RESULT = await axios.get(`${tld}/showroom/api/v1/product_list?q=${QueryString}`, config)
      setProdcuts(RESULT.data.data)
      setLoading(false)
      {
        products.length == 0 ? setAvail(true) : setAvail(false)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => { getSearchedProductList() }, [query])

  return (
    <div className={`nc-PageSearch  ${className} min-h-screen`} data-nc-id="PageSearch">
      <Helmet>
        <title>{query} Price in 2023 India | Agricultural Machinery | Escorts Kubota</title>
      </Helmet>
      <div
        className={`nc-HeadBackgroundCommon top-0 left-0 right-0 w-full bg-primary-200 dark:bg-neutral-800/20`}
        data-nc-id="HeadBackgroundCommon"
      />
      <div className="container lg:pb-28">
        <main>
          {/* FILTER */}
          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-items-center  rounded">
            {loading ?
              <div className="col-span-4 flex justify-center h-full w-full items-center md:min-h-full md:h-96 md:min-w-96 mx-1">
                {/* <CircularProgress /> */}

                {
                  [1, 4, 3, 4 ].map(() => {
                    return  <ShimmerUI />   
                  })
                }

              </div> : products.length === 0 ? <h2 className="mt-32">Product not found!</h2> : products.map((item: any, index: number) => <ProductCard data={item} key={index} />
              )}

          </div>
        </main>
      </div>
    </div>
  );
};

export default PageSearch;
