import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useQuery } from 'react-query'
import { getBannerList } from "services/globalLibrary/lib";
import './libCaro.css'

const CaroHero2 = () => {

    const { isLoading, data, isError } = useQuery('homebannerlist', getBannerList, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) });
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 499, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        }
    };

    return <Carousel
        rewindWithAnimation={true}
        partialVisible={true}
        responsive={responsive}
        swipeable={true}
        showDots={true}
        autoPlay={true}
        ssr={true}
        infinite={true}
        autoPlaySpeed={4000}
        minimumTouchDrag={20}
        customTransition="all .6s ease-out"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-20-px"
        partialVisibilityGutter={35}
        rewind={true}
    >
        {
            isLoading && <div className="hidden lg:block">
                <div class="skeleton relative">
                    <div class="skeleton-right">
                        <div class="square"></div>
                    </div>
                    <div class="skeleton-left">
                        <div class="square"></div>
                    </div>
                </div>
            </div>
        }
        {

            data?.map((item, number) => {
                return <a href={item?.banner_link} className='relative block mx-1' target='_blank' key={number}>
                    <img
                        className="md:h-full w-full"
                        title={item?.image_text}
                        src={item?.background_url}
                        alt="banner image"
                    />
                </a>

            })
        }

    </Carousel>;

}

export default CaroHero2