import SetperForm_add from 'components/userprofile/SetperForm_add'
import BasicBreadcrumbs from "components/BreadComb/BreadComb";
const HomePageData = { Home: "Home", Location: "Sell Used" }
const Sellused = () => {
  return (
    <div className='container mt-6 min-h-screen'>
      <BasicBreadcrumbs Popular={HomePageData} />
      <h2 className='text-lg  hover:bg-blue-250  text-red-600 font-semibold mt-4'>"Photo Khicho Tractor Becho"</h2>
      <div className='text-sm  hover:bg-blue-250  text-slate-400  mt-2'>Enter Details to Sell your Old Tractor below</div>
      <div className='bg-sky-50 p-6 rounded-sm mt-2'>
        <SetperForm_add />
      </div>
    </div>
  )
}

export default Sellused