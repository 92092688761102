
import { FC, useEffect, useId, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import { Product } from "data/data";
import { useQuery } from 'react-query'
import { SeeAllBtn } from "./BtnLikeIcon/SeeAllBtn";
import { getProductList } from "services/globalLibrary/lib";
import CircularProgress from '@mui/material/CircularProgress';
import NextPrev from "shared/NextPrev/NextPrev";


export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
  machine_cat_id?: any
}
const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({ className = "", itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
}) => {
  const { isLoading, data, isError } = useQuery('getProductPopularList', getProductList, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  var isCenter = false;
  var hasNextPrev = true;

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 2.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS, isLoading]);


  return (
    <div className={`nc-SectionSliderProductCard produc_card_bacground${className}`}>
        <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
          <Heading
            className={headingClassName}
            fontClass={headingFontClassName}
            hasNextPrev
          >
            {heading || `Popular Machinery`}
          </Heading>
          <div className="relative">
            <div className="glide__track" data-glide-el="track">
              
              <ul className="glide__slides pb-1">
                {
                  isLoading ? <div className="flex justify-center items-center h-40 w-full"><CircularProgress/></div> : <>
                    {data.map((item: any, index: number) => {
                      return <li key={index} className={`glide__slide ${itemClassName}`}>
                        <ProductCard data={item} />
                      </li>
                    })}
                  </>
                }
              </ul>
            </div>
            {hasNextPrev  && (
              <div className="customGlideButtonPosition">
                <NextPrev onClickNext={() => { }} onClickPrev={() => { }} />
              </div>
            )}
          </div>
        </div>
        <SeeAllBtn name='View All Popular Machinery' />
      </div>
  );
};

export default SectionSliderProductCard;
