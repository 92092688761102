import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./HeroSection2.css";

function SearchTabs() {
    const [toggleState, setToggleState] = useState(1);
    const [index, setIndex] = useState(1)

    const toggleTab = (index) => {

        setToggleState(index);
        setIndex(index)
    };


    useEffect(() => {

    }, [index])
    
    return (
        <div className="container_banner_Search z-22 hidden">
            <h3 className="pb-3 font-light">Find Your Machine Here</h3>
            
            <div className="bloc-tabs">
                <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                >
                    NEW
                </button>
                <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                >
                    USED
                </button>

            </div>

            <div className="content-tabs">
                <div
                    className={toggleState === 1 ? "content  active-content" : "content"}
                >


                    <select name="" id="HPselect" className="my-3">
                        <option value="Select HP">select HP</option>
                        <option value="60">60</option>
                        <option value="80">80</option>
                        <option value="120">120</option>
                        <option value="60">60</option>
                    </select>


                    <select name="" id="selectBrand" className="my-3">
                            <option value="Select HP">select Brand</option>
                            <option value="Mahindra">Mahindra</option>
                            <option value="TATA">TATA</option>
                            <option value="Swaraj">Swaraj</option>
                            <option value="FramTrac">FramTrac</option>
                            <option value="Messy">Messy</option>
                            <option value="JhonDhere">JhonDhere</option>
                            <option value="Kobota">Kobota</option>
                        </select>


                    <button className="bg-blue-400 block w-full py-2 text-white uppercase">
                        Search
                    </button>
                    <button className="mt-3 text-xs font-light hover:underline bg-slate-200 p-1">
                        <Link to='/allProducts'>VIEW ALL</Link>
                    </button>
                </div>


                <div
                    className={toggleState === 2 ? "content  active-content" : "content"}
                >



                    <form action="" method="POST">
                        <select name="" id="selectBrand" className="my-3">
                            <option value="Select HP">select Brand</option>
                            <option value="Mahindra">Mahindra</option>
                            <option value="TATA">TATA</option>
                            <option value="Swaraj">Swaraj</option>
                            <option value="FramTrac">FramTrac</option>
                            <option value="Messy">Messy</option>
                            <option value="JhonDhere">JhonDhere</option>
                            <option value="Kobota">Kobota</option>
                        </select>

                        <select name="" id="selectState" className="my-3">
                            <option value="Select State">Select State</option>
                            <option value="Haryana">Haryana</option>
                            <option value="UP">UP</option>
                            <option value="Bihar">Bihar</option>
                            <option value="MP">MP</option>
                        </select>


                       <div className="flex justify-between">
                       <button className="bg-blue-400 block w-6/12 py-2 text-white uppercase" >
                            Search
                        </button>
                        <button className="bg-cyan-400 block w-6/12 py-2 text-white uppercase" >
                            Sell 
                        </button>
                       </div>
                        <button className="mt-3 text-sm font-light hover:underline">
                            Find all Machinery
                        </button>
                    </form>
                </div>


            </div>
        </div>
    );
}

export default SearchTabs;