import { FC, useEffect, useState, React } from "react";
// import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from "react-helmet";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import ProductCard from "components/ProductCard";
import ProductCardUsed from "components/ProductCardUsed";

// import { useParams } from "react-router-dom"
import axios from "axios";
// import "./all_products.css"
// import ModelQuickView3 from "../../components/ModelQuickView3";
// import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query'
import BasicBreadcrumbs from "components/BreadComb/BreadComb";
import ModelQuickProductUsed from "components/ModelQuickProductUsed";
import { config, tld } from "services/globalvariables/globalvariables";
import { verified_used_machine, allCategories, allSubCategories, used_categorywise_product, used_sub_categorywise_product } from "services/api/allApiHub";
import "./buyused.css"

const HomePageData = { Home: "Home", Location: "Buy Used" }

const BuyUsed = () => {
    const [hidden, setHidden] = useState('hidden');
    const [used, setUsed] = useState([]);
    const [showModalQuickView, setShowModalQuickView] = useState(false);
    const getAllusedProducts = async () => {
        try {
            const Result = await axios.get(`${tld}${verified_used_machine}`, config)
            return Result.data.data
        }
        catch (err) { console.log(err) }
    }
    const { isLoading, data, isError } = useQuery('usedProducts', getAllusedProducts, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })

    const getCategory = async () => {
        try {
            return await axios.get(`${tld}${allCategories}`, config).data.data?.filter((i) => i.sub_category?.length > 0)
        }
        catch (err) { console.log(err) }
    }
    const { data: category, isError: isErrorGetCagegory } = useQuery('getCategoryList', getCategory, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })



    const GetCategoryProduct = async (category) => {
        try {
            const RESULT = await axios.get(`${tld}${used_categorywise_product}${category}`, config)
            setUsed(RESULT.data.data)
        }
        catch (err) { console.log(err) }
    }


    const getSubCategory = async () => {
        try {
            const RESULT = await axios.get(`${tld}${allSubCategories}`, config)
            return RESULT.data.data
        }
        catch (err) { console.log(err) }
    }
    const { isLoading: isLoadingGetCategory, data: sub_category, isError: isErrorGetSubCagegory } = useQuery('getSubCategoryList', getSubCategory, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })



    const GetsubCategoryProduct = async (id) => {
        try {
            const RESULT = await axios.get(`${tld}${used_sub_categorywise_product}${sub_category}`, config)
            setUsed(RESULT.data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <div className={`nc-PageSearch`} data-nc-id="PageSearch">
            <Helmet>
                <title>AgroTracDigital All Products | Buy best Agri Machinery | Proguard | Harvester | Rotavator</title>
                <meta />
            </Helmet>
            <div
                className={`nc-HeadBackgroundCommon  top-0 left-0 right-0 w-full bg-primary-200 dark:bg-neutral-800/20`}
                data-nc-id="HeadBackgroundCommon"
            />
            <div className="container mt-2">

                <BasicBreadcrumbs Popular={HomePageData} />
            </div>
            <div className="container lg:pb-28 mt-4 min-h-screen md:min-h-0">
                <main className="grid grid-cols-1 md:grid-cols-4">
                    {/* FILTER */}
                    {/* ------------------------------ */}
                    {/* Search By Sub Category */}
                    {/* ------------------------------ */}
                    <div className={`for_border md:mr-1 p-1 ${hidden} md:block static w-full bg-white h-full mr-2`} style={{ height: '1400px' }} >
                        <div className="">
                            <div className="min-h-screen">
                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Category</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Typography>
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                {
                                                    category?.map((item, index) => {
                                                        return <div key={index} className="flex items-center py-1 pl-2"><input type="radio" name="the" id={item?.machine_category_id} onChange={() => GetCategoryProduct(item?.machine_category_name)} /> <label htmlFor={item?.machine_category_id} className='ml-2 text-xs  hover:bg-slate-100 p-1  cursor-pointer rounded-md w-full block'>{item?.machine_category_name}</label></div>
                                                    })
                                                }
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                {/* ------------------------------------
                               --------------SubCategory-----------
                               ------------------------------------ */}

                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Subcategory</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {/* <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." /> */}
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                {
                                                    sub_category ? sub_category?.map((item, number) => {
                                                        return <div key={number} className="flex items-center py-1"><input type="radio" name="the" id={item.id} onChange={() => GetsubCategoryProduct(item?.id)} /><label htmlFor={item?.id} className='ml-1 text-xs  hover:bg-slate-100 p-1  cursor-pointer rounded-md w-full block '>{item?.sub_category}</label></div>
                                                    }) : <Skeleton variant="rectangular" width="100%">
                                                        <div style={{ paddingTop: '57%' }} />
                                                    </Skeleton>
                                                }
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* ------------------------------------
                               --------------SubCategory-----------
                               ------------------------------------ */}

                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Brand</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >FarmTrac</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >John Deere</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >New Holland</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Powertrac</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Massey Ferguson</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* ---------------------------------------
                               ------------ Search by Buzet------------
                                --------------------------------------- */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Budget</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <input type="range" min='50000' max='4000000' className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2 text-sm" >0 lakh to 2 lakh</span></div>
                                                <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >2 lakh to 4 lakh</span></div>
                                                <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >4 lakh to 8lakh</span></div>
                                                <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Powertrac</span></div>
                                                <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Massey Ferguson</span></div>
                                                <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* -------------------------------------------
                                ----------------By Model year--------------
                                ------------------------------------------- */}

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Model year</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >FarmTrac</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >John Deere</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >New Holland</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Powertrac</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Massey Ferguson</span></div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>


                        {/* ------------------------------ */}
                        {/* Search By Product Condition */}
                        {/* ------------------------------ */}

                        <button className="block md:hidden w-full mt-6  text-white py-2" onClick={() => setHidden('hidden')} style={{ background: "#4271E6" }}>Apply Filters</button>
                    </div>

                    <div className="col-span-3 ml-2">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 justify-items-center  rounded">
                            {
                                used.length === 0 ? data?.map((item, index) => <ProductCardUsed data={item} key={index} />)

                                    : used?.map((item, index) => <ProductCardUsed data={item} key={index} />)
                            }
                        </div>
                    </div>
                </main>
                <div className="md:hidden">
                    <a href="#" class="float">
                        <i class="fa  my-float" style={{ fontSize: "15px" }} onClick={() => setShowModalQuickView(true)} ><FilterAltIcon style={{ fontSize: '18px' }} /> FILTER</i>
                    </a>
                </div>
            </div>

            <ModelQuickProductUsed
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
            />
        </div>
    )
}

export default BuyUsed