
import { Toaster } from "react-hot-toast";
import MyRouter from "routers/index";

function App() {
  console.log("Process env url : " + process.env.REACT_APP_HOST);
  return (
    <>
      <div>
        <Toaster />
      </div>
      {/* MAIN APP */}
      <div>
        <MyRouter />
      </div>
      
    </>
  );
}

export default App;
