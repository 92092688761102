import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import GradeIcon from '@mui/icons-material/Grade';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import VisibilityOutlinedIcon  from '@mui/icons-material/Visibility';
import ProductStatus from "./ProductStatus";
import Button from '@mui/material/Button';
import "./ProductCard.css"
// import Login from "./loginRegister/Login";
// import Modal from "../components/loginRegister/Product_popup"
// import Product_register_login from "../components/loginRegister/Product_register_login"
import ModalQuickView from "./ModalQuickView";

import ModalQuickView2 from "../components/ModelQuickView2";
export interface ProductCardProps { className?: string; data?: any; }
const ProductCard: FC<ProductCardProps> = ({ className = "", data, }) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  var objdatasting = JSON.parse(localStorage.getItem("data")!)
  const { sub_category,product_name, image_url, id,slug, asking_price, hmr,Location, model_year,
     partner_name,rental_brand,rental_location, machine_category_id, machine_model, machine_pic } = data;

  return (
    <>
      <div className="w-full h-full relative p-2 shadow-md ease-in duration-200 rounded-md bg-white">
        <div
          className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
          data-nc-id="ProductCard"
        >
          <Link to={"/product-detail"} className="absolute inset-0"></Link>
          <div className="relative flex-shrink-0 overflow-hidden z-1 group the_container">
            <div className="cardImage">
              <Link to={`/new-agriculture-machinery-d/${id}/${sub_category}/${slug}/${sub_category}/${product_name}`} className="block">
                {/* <Link to={`/p/${id}/${slug}/${category}/${sub_category}`} className="block"> */}
                <img
                  title={product_name}
                  className=""
                  src={image_url || machine_pic}
                  loading="lazy"
                />
              </Link>
            </div>
            <h2 className={`nc-ProductCard__title product_card_font mb-1 mx-2 line-clamp-2`} title={product_name}>{product_name}</h2>
            <ProductStatus status='New in' />
            {/* eyes */}
            <div className="absolute top-0 right-0 px-1">
              <VisibilityOutlinedIcon sx={{fontSize: 18}} color="primary" />  
              <small style={{ fontSize: ".7rem", display: "inline-block", marginLeft: "5px" }}>{(Math.random() * 10 + 4).toFixed(1)}k</small>
            </div>
          </div>
          {
            asking_price && <div className="flex justify-between my-1">
              <div className="asking_price"></div><div className="model_year" >&#128197; {model_year}</div>
            </div>
          }

          {
            hmr && Location && <div className="flex justify-between">
              <div className="hmr"><AccessTimeFilledIcon style={{ fontSize: ".9rem" }} /> {hmr} Hrs</div>
              <div className="Location"><LocationOnIcon style={{ fontSize: ".8rem" }} /> {Location}</div>
            </div>
          }

          {
            partner_name && <div className="flex justify-between">
              <div className="partner_name"><AccountCircleIcon style={{ fontSize: ".9rem" }} /> {partner_name}</div>
              <div className="Location"><StarIcon style={{ fontSize: ".9rem" }} /> Rating 4.8</div>
            </div>
          }

          {
            machine_model && <div className="flex justify-between">
              <div className="rental_brand">{rental_brand}</div>
              <div className="subCat">{ }</div>
              <div className="subCat">{machine_model}</div>
            </div>
          }

          {
            rental_location && <div className="flex justify-between">
              <div className="rental_location"><LocationOnIcon style={{ fontSize: ".8rem" }} /> {rental_location}</div>
              <div className="rental_price">&#8377;&nbsp;20000/-</div>

            </div>
          }

          {
            machine_category_id && <div className="flex justify-between">
              <div className="rental_location">{machine_category_id}</div>
              <div className="rental_price">&#8377;&nbsp;{asking_price}/-</div>

            </div>
          }


        </div>

        {/* <div className="price_range mb-1">&#8377;From 9.5 to 12 Lac*</div> */}

        {/* {
          asking_price ? <button className="block enquir_now_btn  w-full"
            onClick={() => setShowModalQuickView(true)}
            title="Enquire Now"
          >Contact Seller</button> : <button className="block enquir_now_btn  w-full"
            onClick={() => setShowModalQuickView(true)}
            title="Enquire Now"
          >Enquire now</button>
        } */}

         
        {
          
          asking_price ? <Button variant="outlined" className="customPrimaryButton"
            onClick={() => setShowModalQuickView(true)}
            title="Enquire Now"
          >Contact Seller</Button> : <Button variant="outlined" className="customPrimaryButton"
            onClick={() => setShowModalQuickView(true)}
            title="Enquire Now"
          >Enquire now</Button>
        }

{/* <Button variant="contained">Contained</Button> */}


        <div>
          {
            objdatasting?.full_name ?
              <ModalQuickView
                id={id}
                product_name={product_name}
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
              />
              :
              <ModalQuickView2
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
              />
          }
        </div>


      </div>
    </>
  );
};

export default ProductCard;
