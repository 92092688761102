import { FC, useEffect, useState, React } from "react";
// import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ProductCard from "components/ProductCard";
import { useParams } from "react-router-dom"
import axios from "axios";
// import "./all_products.css"
import ModelQuickView3 from "../../components/ModelQuickView3";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { data } from "components/rentals/rental";
import "./rental.css"

const Rentals = () => {
    return (
        <div className={`nc-PageSearch`} data-nc-id="PageSearch">
            <Helmet>
                <title>AgroTracDigital All Products | Buy best Agri Machinery | Proguard | Harvester | Rotavator</title>
                <meta />
            </Helmet>
            <div
                className={`nc-HeadBackgroundCommon  top-0 left-0 right-0 w-full bg-primary-200 dark:bg-neutral-800/20`}
                data-nc-id="HeadBackgroundCommon"
            />
            <div className="container lg:pb-28 mt-4 min-h-screen md:min-h-0">
                <main className="grid grid-cols-1 md:grid-cols-4">
                    {/* FILTER */}
                    {/* ------------------------------ */}
                    {/* Search By Sub Category */}
                    {/* ------------------------------ */}
                    <div className={`for_border md:mr-1 p-1 md:block static  w-full bg-white h-full mr-2`} >
                        <div >
                            <div >
                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Category</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Typography>
                                            {/* <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." /> */}
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Rotary Tiller</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Sprayers</span></div>
                                                {/* <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >New Holland</span></div> */}
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Harvestors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Post Harvest</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Post Harvest</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Post Harvest</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Post Harvest</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Planting Equipment</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Planting Equipment</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Planting Equipment</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Planting Equipment</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Planting Equipment</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Planting Equipment</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Planting Equipment</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Planting Equipment</span></div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                {/* ------------------------------------
                               --------------SubCategory-----------
                               ------------------------------------ */}

                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Subcategory</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {/* <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." /> */}
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Drone Sprayer</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Orchard Sprayer</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Thresher</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Harvestor</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Baler</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Transplanter</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Baler</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Massey Ferguson</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* ------------------------------------
                               --------------SubCategory-----------
                               ------------------------------------ */}

                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Brand</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >FarmTrac</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >John Deere</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >New Holland</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Powertrac</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Massey Ferguson</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* -------------------------------------------
                                ----------------By Model year--------------
                                ------------------------------------------- */}

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By State</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Haryana</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Haryana</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Haryana</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Haryana</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Haryana</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Haryana</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Haryana</span></div>

                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>


                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By distirct</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Faridabad</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Faridabad</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Faridabad</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Faridabad</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Faridabad</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Faridabad</span></div>
                                                <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >Faridabad</span></div>

                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>


                            </div>
                        </div>
                        {/* ------------------------------ */}
                        {/* Search By Sub Category */}
                        {/* ------------------------------ */}

                        {/* ------------------------------ */}
                        {/* Search By Product Condition */}
                        {/* ------------------------------ */}

                        <button className="block md:hidden w-full mt-6  text-white py-2" style={{ background: "#4271E6" }}>Apply Filters</button>
                    </div>

                    <div className="col-span-3 ml-2">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 justify-items-center  rounded">
                            {
                                data?.product?.map((item, index) => <ProductCard data={item} key={index} />

                                )
                            }
                        </div>
                    </div>
                </main>
                <div className="md:hidden">
                    <a href="#" class="float">
                        <i class="fa  my-float" style={{ fontSize: "15px" }} ><FilterAltIcon style={{ fontSize: '18px' }} /> FILTER</i>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Rentals