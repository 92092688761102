import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import React, { FC, useId } from "react";
import { useEffect } from "react";
import clientSayMain from "images/clientSayMain.png";


import quotationImg2 from "images/quotation2.png";
import { StarIcon } from "@heroicons/react/24/solid";
import "./clientSay.module.css"

export interface SectionClientSayProps {
  className?: string;
}

const DEMO_DATA = [
  {
    id: 1,
    clientName: "Vennam Bhagavan Reddy",
    content:
      "I am working with Agrotracdigital for the past four years I am getting mechanised Paddy Transplating, Track Harvester and Sprayer services.ECS team is professional, transparent, and commited for customer statisfaction.",
  },
  {
    id: 2,
    clientName: "Potharaju Mahendar",
    content:
      "I have attached by  Kobota Track Harvester with Escorts Crop Solutions, My earning have increased, I get my payment in full on time. I will add more machines with them in coming season. ",
  },
  {
    id: 3,
    clientName: "Kamidi Sripatireddy",
    content:
      "I am a franchisee partner to Agrotracdigital, I am earning a decent income with Farming services as well as Machinery Sales. Now I am inventing my money in adding my own fleet.",
  },
];

const SectionClientSay: FC<SectionClientSayProps> = ({ className = "" }) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  useEffect(() => {
    const OPTIONS: Glide.Options = {
      perView: 1,
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [UNIQUE_CLASS]);

  // const renderBg = () => {
  //   return (
  //     <div className="hidden md:block bgColor">
  //       <img className="absolute  -left-40 clientSaytop" src={cust_img_1} alt="" style={{ width: "90px", borderRadius: "50px" }} />
  //       <img
  //         className="absolute bottom-[100px] right-full mr-46"
  //         src={cust_img_1}
  //         style={{ width: "90px", borderRadius: "50px" }}
  //         alt="second img"
  //       />
        
  //       {/* <img
  //         className="absolute top-full left-[140px]"
  //         src={clientSay3}
  //         alt=""
  //       /> */}

  //       <img
  //         className="absolute -bottom-10 right-[140px]"
  //         src={clientSay4}
  //         alt=""
  //       />

  //       <img
  //         className="absolute left-full ml-32 bottom-[80px]"
  //         src={cust_img_2}
  //         style={{ width: "90px", borderRadius: "50px" }}
  //         alt=""
  //       />
  //       <img className="absolute -right-40 top-10 "
  //         src={cust_img_3}
  //         style={{ width: "90px", borderRadius: "50px" }}
  //         alt="" />
  //     </div>
  //   );
  // };

  return (
    <div className="container">
      <h2 className="text-lg font-medium text-[#1550A1] text-center">Testimonials</h2>
      <div
        className={`nc-SectionClientSay relative flow-root  ${className} `}
        data-nc-id="SectionClientSay"
      >
        <div className="relative md:mb-8 max-w-2xl mx-auto bgcolor text-sm">
          {/* {renderBg()} */}

          <img className="mx-auto hidden" src={clientSayMain} alt="" />
          <div className={`mt-12 lg:mt-6 relative ${UNIQUE_CLASS}`}>
            <div className="glide__track " data-glide-el="track">
              <ul className="glide__slides ">
                {DEMO_DATA.map((item) => (
                  <li
                    key={item.id}
                    className="glide__slide flex flex-col items-center text-center"
                  >
                    <span className="w-[80px] h-[80px]">
                      <img src="images/user.png" className="border border-[#D9D9D9] rounded-full" />
                    </span>
                    <span className="block mt-3 text-md font-semibold text-[#F17400]">
                      {item.clientName}
                    </span>
                    <div className="flex items-center space-x-0.5 my-3.5 text-yellow-500">
                      <StarIcon className="w-6 h-6" /> 
                      <StarIcon className="w-6 h-6" />
                      <StarIcon className="w-6 h-6" />
                      <StarIcon className="w-6 h-6" />
                    </div>
                    <span className="block text-md text-[#525252]">{item.content}</span>
                    
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="mt-6 glide__bullets flex items-center justify-center"
              data-glide-el="controls[nav]"
            >
              {DEMO_DATA.map((item, index) => (
                <button
                  key={item.id}
                  className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                  data-glide-dir={`=${index}`}
                ></button>
              ))}
            </div>
          </div>
        </div>
    </div>
    </div>
  );
};

export default SectionClientSay;
