import BasicBreadcrumbs from "components/BreadComb/BreadComb"
import SectionSliderProductCard from "components/SectionSliderProductCard"
import ProductCard from "components/ProductCard"

const BrandDetailPage = () => {
    const item = [

        {
            id: 1,
            machine_pic: 'https://assets.khetigaadi.com/new-implement/KubotaCombineHarvesterV2403-M-DI-TE-CS1T_0_1461318716.jpg',
            product_name: 'Kubota Combine Harvester V2403-M-DI-TE-CS1T '
        },

        {
            id: 2,
            machine_pic: 'https://assets.khetigaadi.com/tr:h-350,w-500/new-tractor/Kubota-MU-5502-4WD1644842590.png',
            product_name: 'Kubota MU 5502 '
        },
        {
            id: 3,
            machine_pic: 'https://assets.khetigaadi.com/new-implement/KubotaRiceTransplanterNSP6W_0_1498477037.png',
            product_name: 'Kubota Rice Transplanter'
        },
        {
            id: 4,
            machine_pic: 'https://assets.khetigaadi.com/new-implement/KubotaCombineHarvesterV2403-M-DI-TE-CS1T_0_1461318716.jpg',
            product_name: 'Kubota Rice Transplanter'
        },

        {
            id: 5,
            machine_pic: 'https://assets.khetigaadi.com/new-implement/KubotaCombineHarvesterV2403-M-DI-TE-CS1T_0_1461318716.jpg',
            product_name: 'Kubota Combine Harvester V2403-M-DI-TE-CS1T '
        },

        {
            id: 6,
            machine_pic: 'https://assets.khetigaadi.com/tr:h-350,w-500/new-tractor/Kubota-MU-5502-4WD1644842590.png',
            product_name: 'Kubota MU 5502 '
        },
        {
            id: 7,
            machine_pic: 'https://assets.khetigaadi.com/new-implement/KubotaCombineHarvesterV2403-M-DI-TE-CS1T_0_1461318716.jpg',
            product_name: 'Kubota Combine Harvester V2403-M-DI-TE-CS1T '
        },

        {
            id: 8,
            machine_pic: 'https://assets.khetigaadi.com/tr:h-350,w-500/new-tractor/Kubota-MU-5502-4WD1644842590.png',
            product_name: 'Kubota MU 5502 '
        },
    ]
    const HomePageData = { Home: "Home", Location: "Kubota Tractor models" }
    return <>
        <div className="mb-64">
            <div className="container ">
                <div className="mt-1">
                    <BasicBreadcrumbs sx='FontSize:.9rem' Popular={HomePageData} />

                </div>
                <div className="lg:h-24 lg:w-full">
                    <h2 className="my-2 text-2xl">Kubota Implements</h2>
                    <p className="text-sm text-slate-500 my-2 font-light">The Kubota tractor was launched in 1890 by Goshiro Kubota. Kubota aimed differently with the goals of solving the world's problems in food, water, and
                        the environment they created in the Kubota Tractor com ... <span className="text text-zinc-950" ><a href="#" style={{ textDecoration: "underline", color: "#000" }}>Read more</a></span></p>
                </div>

                <div className="grid grid-cols-4 gap-3">
                    {
                        item.map((item, index) => {
                            return <div className="h-full w-full">
                                <ProductCard key={item.id} data={item} />
                            </div>

                        })
                    }
                </div>

                <div className="lg:h-full lg:w-full text-sm text-black-500 my-2">
                    <h2 className="mt-8 text-2xl">About Kubota Tractors</h2>
                    <div className="shadow my-2 font-light py-2">
                        The Kubota tractor was launched in 1890 by Goshiro Kubota. Kubota aimed differently with the goals of solving the world's problems in food, water, and the environment they created in the Kubota Tractor company.
                    </div>
                    <div className="shadow my-2 font-light py-2">
                        Kubota Tractor is one of the leading manufacturers in India. In India Kubota plays a vital role in forming major agricultural parts. In 2008, Kubota was founded in India as a part of Kubota Corporation (Japan) and Kubota Agricultural Machinery India Pvt. Ltd.                 The Kubota tractor network is increasing continuously.
                    </div>

                    <div className="shadow my-2 font-light py-2">
                        Kubota tractors have robust, huge, and strong Tractor engines for quality functioning in required conditions. Kubota tractors are easy to handle and come with unique styling and trusted identity at the best prices.
                    </div>

                    <div className="shadow my-2 font-light py-2">
                        Kubota tractor offers 2 wheel drive and 4 wheel drive tractors from 21 to 55 HP range with a lightweight design and efficient engine and affordable price.
                        Kubota tractor has 10+ models in 21-55 HP categories. Kubota tractor price starts at Rs. 4.15 Lakh. The most costly Kubota tractor model is Kubota MU 5501 4WD priced at Rs. 10.12 Lakh.
                    </div>



                </div>
                {/* <h2 className="mt-8 text-2xl text-black-500">Why Choose Kubota Tractor?</h2> */}


                <div className="lg:h-full lg:w-full text-sm text-black-500 my-2">
                    <h2 className="mt-8 text-2xl">Why Choose Kubota Tractor?</h2>
                    <div className="shadow my-2 font-light py-2">
                        Kubota tractor provides a whole alternative of merchandise from mini Kubota tractors to large-sized Kubota tractors for numerous usages. In addition, Kubota tractor implements are designed for plowing, cultivator, and plenty of additional
                        The Kubota tractor was launched in 1890 by Goshiro Kubota. Kubota aimed differently with the goals of solving the world's problems in food, water, and the environment they created in the Kubota Tractor company.
                        Kubota continuously provides its levels of performance and honesty has assisted the corporate to start powerful esteem within the world. Kubota provides a full list of tractors.
                    </div>
                    <div className="shadow my-2 font-light py-2">
                        Kubota Tractor is one of the leading manufacturers in India. In India Kubota plays a vital role in forming major agricultural parts. In 2008, Kubota was founded in India as a part of Kubota Corporation (Japan) and Kubota Agricultural Machinery India Pvt. Ltd.                 The Kubota tractor network is increasing continuously.
                    </div>

                    <div className="shadow my-2 font-light py-2">
                        Kubota tractors have robust, huge, and strong Tractor engines for quality functioning in required conditions. Kubota tractors are easy to handle and come with unique styling and trusted identity at the best prices.
                    </div>

                    <div className="shadow my-2 font-light py-2">
                        Kubota tractor offers 2 wheel drive and 4 wheel drive tractors from 21 to 55 HP range with a lightweight design and efficient engine and affordable price.
                        Kubota tractor has 10+ models in 21-55 HP categories. Kubota tractor price starts at Rs. 4.15 Lakh. The most costly Kubota tractor model is Kubota MU 5501 4WD priced at Rs. 10.12 Lakh.
                    </div>



                </div>
            </div>
        </div>
    </>
}


export default BrandDetailPage