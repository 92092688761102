import React, { FC, useEffect, useState, useRef } from "react";
import "./desktop_nav.css"
import MenuBar from "shared/MenuBar/MenuBar";
// import Navigation from "shared/Navigation/Navigation";
import { Link } from "react-router-dom";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
// import { getUser } from "loginUtils/Common";
import ModelQuickView2 from "../../components/ModelQuickView2";
import agro_logo from './header_logo/agrotrac_log.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Autocomplete from '@mui/material/Autocomplete';
import { useHistory } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { tld, config } from "services/globalvariables/globalvariables";
import axios from "axios";
import { useQuery } from "react-query";
import { useLocation } from 'react-router-dom';


export interface MainNav2LoggedProps { }


const storeProductSession = async () => {
  try {
    let ProductUrl = `${tld}/showroom/api/v1/product_list/`
    const RESULT = await axios.get(ProductUrl, config)
    return sessionStorage.setItem('product_list_sujj', JSON.stringify(RESULT.data.data))

  }
  catch (err) {
    console.log(err)
  }
}








const SearchBar = () => {
  const [search, setSearch] = useState<any>('');
  const [hidden, SetHidden] = useState('hidden')
  const [selectStr, setSelectStr] = useState();
  const ref = useRef(null);
  const product_list_sujj = JSON.parse(sessionStorage.getItem("product_list_sujj")!)
  const handleSubmit = (e: any) => {
    e.preventDefault();
    SetHidden('hidden');
    window.location.href = `/page-search/${search}`
  }


  const makeSearch = (e: any) => {
    SetHidden('block');
    setSearch(e.target.value);
  }



  return <>
    <form className="relative w-full" method="POST" autoComplete="off" onSubmit={handleSubmit}>
      <label
        htmlFor="search-input"
        className="text-neutral-500 dark:text-neutral-300"
      >
        <span className="sr-only">Search all icons</span>
        <Input
          required
          className="w-full bg-[#F0F0F0] h-11 text-sm border-0"
          name='searchnewproducts'
          type="search"
          onSelect={e => makeSearch(e)}
          value={search}
          placeholder="Find machinery by name, brand, category etc."
          sizeClass="pt-4 pb-4 pr-10"
          rounded="rounded-md"
        />
        <div className={`max-h-48 absolute bg-white w-full overflow-auto  transition  ease-out border divide-y divide-slate-200 rounded-md ${hidden}`} id="product_sujj">
          {
            product_list_sujj?.filter((i: any) => i.product_name.toUpperCase().match(search.toUpperCase())).map((i: any, index: number) => <div className="p-4 cursor-pointer" onClick={() => setSearch(i.product_name)} >{i.product_name}</div>)
          }
        </div>
        <button
          className="absolute right-0 h-full w-10 rounded-r-md top-1/2 transform -translate-y-1/2 bg-[#1550A1]"
          
          type="submit"
          onClick={(e) => handleSubmit(e)}
        >
          <i className="las la-search text-lg text-white"></i>
        </button>
        
      </label>
    </form>
  </>
}

const SearchMobile = () => {
  const [search, setSearch] = useState<any>('');
  const [hidden, SetHidden] = useState('hidden')
  const handleSubmit = (e: any) => {
    e.preventDefault();
    window.location.href = `/page-search/${search}`
  }





  const product_list_sujj = JSON.parse(sessionStorage.getItem("product_list_sujj")!)
  return <>
    <form action="" method="POST" autoComplete="off" className="font-xs" onSubmit={handleSubmit}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        size="small"
        options={product_list_sujj?.map((item: any) => item?.product_name || "")}
        // onChange={(event, value) => makeSearch(value)}
        onChange={(e, value) => { setSearch(value) }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={search}
            label="Find machinery by name, brand, category etc."
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </form>
  </>
}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState('');

  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const [profileObject, setprofileObject] = useState<any>()
  const [sujj, Setsujj] = useState([]);
  const [search, setSearch] = useState<any>('');
  const [hidden, SetHidden] = useState('hidden')
  const [activeTab, setActiveTab] = useState<number>();
  const { isLoading, data, isError } = useQuery('storeProductSession', storeProductSession, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })
  var objdatasting = JSON.parse(localStorage.getItem("data")!)
  const product_list_sujj = JSON.parse(sessionStorage.getItem("product_list_sujj")!)

  const history = useHistory();
  function handleNavigateSellUsed() {
    history.push("/sell-used");
  }

  useEffect(() => {
    setActiveRoute(location.pathname);
    var objdatasting = JSON.parse(localStorage.getItem("data")!)
    setprofileObject(objdatasting)
  }, [location,activeRoute])

  const ProductSearch = () => {

    const product_list_sujj = JSON.parse(sessionStorage.getItem("product_list_sujj")!)
    const MakeVisible = () => { SetHidden('block') }

    return <>
      <div className="container-fluid md:container text-xs h-[100px]" id="id_input_form">
        <div className="flex items-center justify-between h-full">
          <div className="flex items-center hidden md:block">
            <Link to="/">
              <div className="" style={{ width: "200px" }}>
                <img src={agro_logo} className='w-full h-full' alt="logo" />
              </div>
            </Link>
          </div>
          <div className="relative w-11/12 my-1 md:hidden">
            {/* For mv */}
            {
              <SearchMobile />
            }
          </div>
          {/* search for dv */}
          <header className="max-w-2xl md:max-w-md flex md:w-full hidden md:block">
            <SearchBar />
          </header>
          <div className="flex justify-end  hidden lg:block" >
            {/* <small className="ml-6">
              <select name="" id="" className="text-md border-none outline-none focus:border-none w-auto" style={{ fontWeight: '500' }}>
                <option value="" className="font-xs">Language</option>
                <option value="">Hindi</option>
                <option value="">English</option>
                <option value="">Punjabi</option>
              </select>
            </small> */}
            <div id="google_translate_element"></div>
          </div>
          {/* ---------------------------------- */}
          {/* Login/Register Desktop  */}
          {/* ---------------------------------- */}
          <div className="hidden md:block">
            {
              profileObject?.full_name ?
                <div className="">
                  <small className="text-xs cursor-pointer hidden lg:block text-slate-500 block">
                    <Link to='/profile' className="login_reg_mobile">Hi, <b>{profileObject?.full_name}</b></Link>
                  </small>
                </div> : <div ><Link to="#" onClick={() => setShowModalQuickView(true)} className="login_reg_mobile"> Login/Register</Link></div>
            }
          </div>
        </div>
      </div>
    </>
  }

  const renderContent = () => {

    return (
      <div className="flex justify-between h-full">
        <div className="flex items-center lg:hidden  max-w-md">
          <MenuBar />
        </div>
        <div className="lg:flex-1 flex items-center md:hidden mt-2">
          <Link to="/">
            <div className="" style={{ width: "150px" }}>
              <img src={agro_logo} className='w-full' alt="logo" />
            </div>
          </Link>
        </div>

        <div className="flex-[2] hidden lg:flex justify-around items-center mx-4 text-sm desktop_navigation">
          {/* <Navigation /> */}
          <Link to="/allproducts" className={activeRoute === '/allproducts' ? 'active_link' : 'text-white'}>Buy New</Link>
          <Link to="/buy-used" className={activeRoute === '/buy-used' ? 'active_link' : 'text-white'}>Buy Used</Link>
          <Link to='/' className="text-white">Rentals</Link>
          <Link to="/" className="text-white">Service</Link>
          <Link to="/" className="text-white">Parts</Link>
          <Link to="/" className="text-white">Reviews</Link>
          {
            objdatasting?.full_name ?
              <div className="px-1 text-dark buy_used_box_ani">
                <small className="text-xs cursor-pointer hidden lg:block"> </small>
                <Link to='/sell-used' className="text-white" onClick={handleNavigateSellUsed}>Sell Used</Link>
              </div> :
              <div className="px-1 buy_used_box_ani" >
                <Link to="#" onClick={() => setShowModalQuickView(true)} className="text-white">Sell Used</Link>
              </div>
          }
        </div>

        <select name="" id="" className='outline-none border-none focus:border-none focus:outline-none hidden md:block w-[150px] bg-transparent text-white pl-5 text-sm'>
          <option value="Advertise with Us">Resources</option>
          <option value="Advertise with Us">Advertise with Us</option>
          <option value="Prime Membership">Prime Membership</option>
          <option value="Guest Post">Guest Post</option>
        </select>

        {/* ---------------------------------- */}
        {/* Login/Register M-View  */}
        {/* ---------------------------------- */}

        <div className="md:hidden mt-2">
          {
            profileObject?.full_name ?
              <div className="">
                <Link to='/profile' className="login_reg_mobile"><AccountCircleIcon /></Link>
              </div> : <div ><Link to="#" onClick={() => setShowModalQuickView(true)} className="login_reg_mobile"><AccountCircleIcon /></Link></div>
          }
        </div>

        <select name="" id="" className="text-md border-none outline-none focus:border-none bg-transparent text-white ml-6 text-sm">
              <option value="" className="" >Location</option>
              <option value="">Delhi</option>
              <option value="">Noida</option>
              <option value="">Gurgaon</option>
            </select>  

        {/* <div className="flex items-center justify-center text-slate-700 dark:text-slate-100 hidden md:block">
          <div className="flex justify-end">
            <small className="ml-6">
              
            </small>
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 dark:border-slate-700">
      <div className="hidden md:block relative">
        <ProductSearch />

      </div>

      <div className="container-fluid" >
        <div className="container h-12 bg-[#1550A1]">
          {renderContent()}
        </div>
      </div>
      <div className="md:hidden ">
        <ProductSearch />
      </div>
      <ModelQuickView2
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </div>


  );
};

export default MainNav2Logged;
