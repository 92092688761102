import "react-multi-carousel/lib/styles.css";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useQuery } from 'react-query'
// import { getBannerList } from "services/globalLibrary/lib";
import { getBrandList } from "services/globalLibrary/lib";
// import './libCaro.css'

const PopuBrands = () => {

    const { isLoading, data, isError } = useQuery('brandlist', getBrandList, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) });
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 6,
            partialVisibilityGutter: 30
        },
        mobile: {
            breakpoint: { max: 499, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        }
    };

    useEffect(()=>{

    },[isLoading])

    return <Carousel
    // partialVisibilityGutter={35}
        rewindWithAnimation={true}
        partialVisible={true}
        responsive={responsive}
        swipeable={true}

        autoPlay={true}
        ssr={true}
        infinite={true}
        autoPlaySpeed={4000}
        minimumTouchDrag={20}
        customTransition="all .6s ease-out"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-20-px"
        rewind={true}
    >
        {
            isLoading && <div>wait...</div>
        }
        {

            data?.map((item: any, number: number) => {
                return <>
                    <div className="relative bg-white rounded-md shadow-md" style={{ height: '121px', width: '190px' }} >
                        <a href={item?.banner_link} className='relative block' target='_blank' key={number}>

                            <img
                                className="object-contain p-1"
                                style={{ height: 120, width: "100%" }}
                                title={item?.image_text}
                                src={item?.image_url}
                                alt="banner image"
                            />
                        </a>
                    </div>
                    <div className="text-center mt-4 mr-4"><a href="#" className="text-[0.825rem] text-capitalize">{item?.brand_name}</a></div>
                </>

            })
        }

    </Carousel>;

}

export default PopuBrands