
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import FormHelperText from '@mui/material/FormHelperText';

import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useFormContext } from "react-hook-form";



const Sell = () => {
    const [location, setLocation] = useState([]);
    const [hidden, setHidden] = useState(false);
    const { control, setValue, register, formState: { errors, defaultValues }, setError, watch } = useFormContext();

    // const avSell = watch("available_for_sell");
    const available_for_sell = watch("available_for_sell");
    const financierShow = watch('machine_paper.0.financier');
    const insuranceShow = watch('machine_paper.0.insurance');

    const [InvoiceImage, SetMachineInvoicImage] = useState();
    const [InvoiceImageRC, SetMachineRCImage] = useState()
    const [InvoiceImageFN, SetMachineFNImage] = useState()
    const [InvoiceImageInc, SetMachineIncImage] = useState()


    const [InvoiceImageFinancier, SetMachineFinancierImage] = useState();
    const [inso_doc, SetInsoLoading] = useState();
    const [loadingInvoic, SetLoadingInvoice] = useState();
    const [loadingRC, SetLoadingRC] = useState();

    const invoiceDoc = watch('machine_paper.0.insurance_doc')
    const HandleSell = (e) => {
        if (e.target.checked == true) {
            setValue('available_for_sell', true);

        }
        if (e.target.checked == false) {
            setValue('available_for_sell', false);
        }

    }

    // Upload Invoice doc
    const uploadImageInvoice = async (e) => {
        SetLoadingInvoice(true);
        const formData = new FormData();
        formData.append('image', e.target.files[0])
        formData.append('key', "qwerty")
        axios.post(`${process.env.REACT_APP_HOST}/fleetowners/v1/imageupload/`,
            formData, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
            .then(function (response) {
                setValue('machine_paper.0.invoice', response.data.image_url);
                SetMachineInvoicImage(response.data.image_url);
                SetLoadingInvoice(false);
            })
            .catch(function (error) {
                console.log(error.response);
                if (error.response.status === 400) {
                    alert('Something went worng ! Please contact your Admin.');
                }
            });
    }

    // Upload RC doc
    const uploadImageRC = async (e) => {
        SetLoadingRC(true);
        const formData = new FormData();
        formData.append('image', e.target.files[0])
        formData.append('key', "qwerty")
        axios.post(`${process.env.REACT_APP_HOST}/fleetowners/v1/imageupload/`,
            formData, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
            .then(function (response) {
                setValue('machine_paper.0.rc', response.data.image_url);
                SetMachineRCImage(response.data.image_url);
                SetLoadingRC(false);
            })
            .catch(function (error) {
                console.log(error.response);
                if (error.response.status === 400) {
                    alert('Something went worng ! Please contact your Admin.');
                }
            });
    }

    // Upload Finance doc
    const uploadImageFinancier = async (e) => {
        SetMachineFinancierImage(true);
        const formData = new FormData();
        formData.append('image', e.target.files[0])
        formData.append('key', "qwerty")
        axios.post(`${process.env.REACT_APP_HOST}/fleetowners/v1/imageupload/`,
            formData, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
            .then(function (response) {
                setValue('machine_paper.0.finance_doc', response.data.image_url);
                SetMachineFNImage(response.data.image_url);
                SetMachineFinancierImage(false);
            })
            .catch(function (error) {
                console.log(error.response);
                if (error.response.status === 400) {
                    alert('Something went worng ! Please contact your Admin.');
                }
            });
    }

    // Upload Ins doc

    const uploadImageInsou = async (e) => {
        SetInsoLoading(true);
        const formData = new FormData();
        formData.append('image', e.target.files[0])
        formData.append('key', "qwerty")
        axios.post(`${process.env.REACT_APP_HOST}/fleetowners/v1/imageupload/`,
            formData, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
            .then(function (response) {
                setValue('machine_paper.0.insurance_doc', response.data.image_url);
                SetMachineIncImage(response.data.image_url);
                SetInsoLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                if (error.response.status === 400) {
                    alert('Something went worng ! Please contact your Admin.');
                }
            });
    }

    const getLocation = async () => {
        let URL = `${process.env.REACT_APP_HOST}`
        const config = { headers: { "X-Api-Key": `${process.env.REACT_APP_API_KEY}` } };
        let ProductUrl = `${URL}/locations/api/v1/territory_list/`
        const RESULT = await axios.get(ProductUrl, config)
        console.log(RESULT.data, 'locatinkkkkkkkkkkkkkkkkkkkk')
        setLocation(RESULT?.data?.data);
    }

    const handleAskingPrice = () => {
        //    console.log(errors.asking_price.type,'errors.asking_price.type')
        alert();
    }

    const handleTerii = (e) => {
        const value = e.target.value;
        console.log(value, 'valueee')
    }



    useEffect(() => {
        getLocation();
    }, []);


    return (
        <>
            <div className="h-30 mt-4">
                <div className="flex mt-6 items-center bg-blue-100 w-full py-4 px-2 rounded-sm mb-2">
                    <label htmlFor="">Available for Sell &nbsp;&nbsp;&nbsp;</label>
                    <input type="checkbox"  {...register('available_for_sell', {})} id='available_for_sell' onChange={(e) => HandleSell(e)} />&nbsp;&nbsp;Yes
                </div>
                {/* Location  */}


                {/* <Controller
                    control={control}
                    name="territory"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => {
                        return <Autocomplete
                            onChange={(event, item) => {
                                console.log(item.uuid, 'itemkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
                                onChange(item?.uuid);
                            }}
                            value={value}
                            options={location}
                            getOptionLabel={(item) => (item.name ? item.name : "")}
                            getOptionSelected={(item) =>  item.uuid }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Location/Area"
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.item}
                                    name='territory'
                                    helperText={errors.item && "item required"}
                                    required
                                />
                            )}
                        />
                    }}
                /> */}








                <Controller
                    control={control}
                    register={register}
                    setValue={setValue}
                    name="territory"
                    rules={{ required: "*required" }}

                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel id="territory">Location/Area</InputLabel>
                            <Select
                                
                                labelId="territory"
                                id="demo-simple-select-territory"
                                label="territory"
                                {...register("territory")}
                                {...field}
                                error={Boolean(errors?.territory)}
                                helperText={errors.territory?.message}
                                sx={{ width: "100%" }}
                            >
                                {
                                    location?.map((item, index) => {
                                        return <MenuItem key={index} name={item.uuid} value={item.uuid}>{item.name}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText sx={{ color: "red" }}>{errors.territory?.message}</FormHelperText>
                        </FormControl>
                    )}
                />








                {
                    available_for_sell && (<div className=''>
                        {/* Asking Price */}
                        <Controller
                            control={control}
                            name="asking_price"
                            rules={{
                                required: "*required", maxLength: {
                                    value: 7,
                                    message: "Asking price cannot be more than 7 digit"
                                }, pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Please enter a number',
                                }

                            }}


                            render={({ field }) => (
                                <TextField
                                    id="asking-price"
                                    label="Asking Price"
                                    variant="filled"
                                    placeholder="Provide Asking Price"
                                    onChange={handleAskingPrice}
                                    fullWidth
                                    margin="normal"
                                    {...field}
                                    error={Boolean(errors?.asking_price)}
                                    helperText={errors?.asking_price?.message}

                                />
                            )}
                        />

                        <div className="images_for_doc h-36 flex justify-center items-center relative">

                            {
                                InvoiceImage ? <div className='h-36 w-1/2 p-2 relative mx-1' style={{ border: "1px solid #ccc" }}>
                                    <label htmlFor="invoice" className='absolute top-1 left-2  rounded-lg cursor-pointer'>Edit</label>
                                    <input type="file" id='invoice' className='w-full h-full p-2 absolute top-0 left-0 invisible' onChange={uploadImageInvoice} />
                                    {
                                        loadingInvoic && <CircularProgress />
                                    }
                                    <img src={InvoiceImage} className='w-full h-full p-2 object-contain' alt="" />
                                </div> :
                                    <div className="w-1/2 h-full   relative shadow-lg rounded-md mx-1" style={{ border: "1px solid #ccc" }} id='myfin'>
                                        <label htmlFor="invoice" className='flex flex-col h-full w-full justify-center items-center cursor-pointer'>
                                            {
                                                loadingInvoic && <CircularProgress />
                                            }
                                            <AddIcon style={{ fontSize: "3.2rem", background: "white", padding: '3px', borderRadius: "8px" }} />
                                            <h4 className='mt-2'>Upload Invoice</h4>
                                            {/* <small className='text-red-600'>Required</small> */}
                                        </label>
                                        <input type="file" id='invoice' className='w-full h-full p-2 absolute top-0 left-0' onChange={uploadImageInvoice} required />
                                    </div>
                            }


                            {
                                InvoiceImageRC ? <div className='h-36 w-1/2 p-2 relative' style={{ border: "1px solid #ccc" }}>
                                    <label htmlFor="rc" className='absolute top-1 left-2  rounded-lg cursor-pointer'>Edit</label>
                                    {
                                        loadingRC && <CircularProgress />
                                    }
                                    <input type="file" id='rc' className='w-full h-full p-2 absolute top-0 left-0 invisible' onChange={uploadImageRC} />
                                    <img src={InvoiceImageRC} className='w-full h-full p-2 object-contain' alt="mmmmmmm" />
                                </div> :
                                    <div className="w-1/2 h-full   relative shadow-lg rounded-md" style={{ border: "1px solid #ccc" }} id='myfin'>
                                        <label htmlFor="rc" className='flex flex-col h-full w-full justify-center items-center cursor-pointer'>
                                            {
                                                loadingRC && <CircularProgress />
                                            }
                                            <AddIcon style={{ fontSize: "3.2rem", background: "white", padding: '3px', borderRadius: "8px" }} />
                                            <h4 className='mt-2'>Upload RC document</h4>
                                            {/* <small className='text-red-600'>Required</small> */}
                                        </label>
                                        <input type="file" id='rc' className='w-full h-full p-2 absolute top-0 left-0' onChange={uploadImageRC} required />

                                    </div>
                            }




                            {/* <div className="w-1/2 h-full bg-gray-100 mx-1 rounded-md shadow-lg" style={{ border: "1px solid #ccc" }}>
                                <label htmlFor="rc" className='flex flex-col h-full w-full justify-center items-center cursor-pointer'>
                                    <AddIcon style={{ fontSize: "3.2rem", background: "white", padding: '3px', borderRadius: "8px" }} />
                                    <h4 className='mt-2'>Upload RC document</h4>
                                </label>
                                <input type="file" id='rc' className='w-full h-full p-2 invisible' />
                            </div> */}

                        </div>

                        <Controller
                            control={control}
                            register={register}
                            setValue={setValue}
                            name="machine_paper.0.ownership"
                            render={({ field }) => (
                                <FormControl fullWidth sx={{marginTop:"20px"}}>
                                    <InputLabel id="ownership">Ownership</InputLabel>
                                    <Select
                                        labelId="Machine ownership"
                                        id="demo-simple-select-ownership"
                                        label="ownership"
                                        {...register("machine_paper.0.ownership")}
                                        {...field}
                                        sx={{ width: "100%", marginBottom: "15px", marginTop: "10px" }}
                                    >

                                        <MenuItem value='first_owner'>First Owner</MenuItem>
                                        <MenuItem value='second_owner'>Second Owner</MenuItem>
                                        <MenuItem value='third_owner'>Third Owner</MenuItem>

                                    </Select>
                                </FormControl>

                            )}
                        />

                        <Controller
                            control={control}
                            register={register}
                            setValue={setValue}
                            name="machine_paper.0.financier"

                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="financier">financier</InputLabel>
                                    <Select
                                        labelId="Machine financier"
                                        id="demo-simple-select-financier"
                                        label="financier"
                                        {...register("machine_paper.0.financier")}
                                        {...field}
                                        error={Boolean(errors?.financier)}
                                        helperText={errors?.financier?.message}
                                        sx={{ width: "100%", marginBottom: "15px", marginTop: "10px" }}
                                    >

                                        <MenuItem value='yes'>Yes</MenuItem>
                                        <MenuItem value='no'>No</MenuItem>
                                    </Select>
                                </FormControl>

                            )}
                        />



                        {
                            financierShow === 'yes' && (
                                <div className="images_for_doc h-36 flex justify-center items-center">
                                    {
                                        InvoiceImageFN ? <div className='h-36 w-1/2 p-2 relative' style={{ border: "1px solid #ccc" }}>
                                            <label htmlFor="financier_docu" className='absolute top-1 left-2  rounded-lg cursor-pointer'>Edit</label>
                                            {
                                                InvoiceImageFinancier && <CircularProgress />
                                            }
                                            <input type="file" id='financier_docu' className='w-full h-full p-2 absolute top-0 left-0 invisible' onChange={uploadImageFinancier} />
                                            <img src={InvoiceImageFN} className='w-full h-full p-2 object-contain' alt="mmmmmmm" />
                                        </div> :
                                            <div className="w-1/2 h-full  bg-gray-100 relative shadow-lg rounded-md" style={{ border: "1px solid #ccc" }} id='myfin'>
                                                <label htmlFor="financier_docu" className='flex flex-col h-full w-full justify-center items-center cursor-pointer'>
                                                    {
                                                        InvoiceImageFinancier && <CircularProgress />
                                                    }
                                                    <AddIcon style={{ fontSize: "3.2rem", background: "white", padding: '3px', borderRadius: "8px" }} />
                                                    <h4 className='mt-2'>Upload financier document</h4>
                                                    {/* <small className='text-red-600'>Required</small> */}
                                                </label>
                                                <input type="file" id='financier_docu' className='w-full h-full p-2 absolute top-0 left-0' onChange={uploadImageFinancier} />

                                            </div>
                                    }

                                </div>
                            )
                        }


                        <Controller
                            control={control}
                            register={register}
                            setValue={setValue}
                            name="machine_paper.0.insurance"
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="insurance">insurance</InputLabel>
                                    <Select
                                        labelId="insurance"
                                        id="demo-simple-select-insurance"
                                        label="insurance"
                                        {...register("machine_paper.0.insurance")}
                                        {...field}
                                        error={Boolean(errors?.insurance)}
                                        helperText={errors?.insurance?.message}
                                        sx={{ width: "100%", marginBottom: "15px", marginTop: "10px" }}
                                    >

                                        <MenuItem value='yes'>Yes</MenuItem>
                                        <MenuItem value='no'>No</MenuItem>

                                    </Select>
                                </FormControl>

                            )}
                        />
                        {
                            insuranceShow === 'yes' && (
                                <div className="images_for_doc h-36 flex justify-center items-center">
                                    {
                                        InvoiceImageInc ? <div className='h-36 w-1/2 p-2 relative' style={{ border: "1px solid #ccc" }}>
                                            <label htmlFor="inso_docu" className='absolute top-1 left-2 bg-gray-100 rounded-lg cursor-pointer'>Edit</label>
                                            {
                                                inso_doc && <CircularProgress />
                                            }
                                            <input type="file" id='inso_docu' className='w-full h-full p-2 absolute top-0 left-0 invisible' onChange={uploadImageInsou} />
                                            <img src={InvoiceImageInc} className='w-full h-full p-2 object-contain' alt="mmmmmmm" />
                                        </div> :
                                            <div className="w-1/2 h-full  bg-gray-100 relative shadow-lg rounded-md" style={{ border: "1px solid #ccc" }} id='myfin'>
                                                <label htmlFor="inso_docu" className='flex flex-col h-full w-full justify-center items-center cursor-pointer'>
                                                    {
                                                        inso_doc && <CircularProgress />
                                                    }
                                                    <AddIcon style={{ fontSize: "3.2rem", background: "white", padding: '3px', borderRadius: "8px" }} />
                                                    <h4 className='mt-2'>Upload financier document</h4>
                                                    {/* <small className='text-red-600'>Required</small> */}
                                                </label>
                                                <input type="file" id='inso_docu' className='w-full h-full p-2 absolute top-0 left-0' onChange={uploadImageInsou} />

                                            </div>
                                    }

                                </div>
                            )
                        }


                        <Controller
                            control={control}
                            register={register}
                            setValue={setValue}
                            name="machine_paper.0.loan"
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="loan">Loan</InputLabel>
                                    <Select
                                        labelId="loan"
                                        id="demo-simple-select-Loan"
                                        label="loan"
                                        {...register("machine_paper.0.loan")}
                                        {...field}
                                        error={Boolean(errors?.loan)}
                                        helperText={errors?.loan?.message}
                                        sx={{ width: "100%", marginBottom: "15px", marginTop: "10px" }}
                                    >

                                        <MenuItem value='yes'>Yes</MenuItem>
                                        <MenuItem value='no'>No</MenuItem>

                                    </Select>
                                </FormControl>

                            )}
                        />
                    </div>
                    )
                }
            </div>
        </>
    );
};

export default Sell