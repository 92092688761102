
import { useEffect, useState } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { useFormContext } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';


const MachinePhotos = ({ required, setRequired }) => {
    const { formState: { errors }, setValue, getValues, register } = useFormContext();
    const [machineImage, SetMachineImage] = useState();
    const [loading, SetLoading] = useState();
    const mulipleImages = getValues('machine_pic');
    // const mulipleImages = [];
    const machine_image = getValues('machine_pic')



    const uploadImage = async (e, i) => {
        SetLoading(true);
        const formData = new FormData();
        formData.append('image', e.target.files[0])
        formData.append('key', "qwerty")
        axios.post(`${process.env.REACT_APP_HOST}/fleetowners/v1/imageupload/`,
            formData, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
            .then(function (response) {
                // setValue('machine_pic', response.data.image_url);
                mulipleImages[i] = response?.data?.image_url
                setValue('machine_pic', mulipleImages);
                // SetMachineImage(response.data.image_url);
                SetLoading(false);
                // ChangeRequired();

            })
            .catch(function (error) {
                console.log(error.response);
                if (error.response.status === 400) {
                    alert('Something went worng ! Please contact your Admin.');
                }
                if (error.response.status === 500) {
                    alert('Server error! Please contact your Admin.');
                }
            });
    }



    const edit = async (e, i) => {
        e.preventDefault();
        SetLoading(true);
        const formData = new FormData();
        formData.append('image', e.target.files[0])
        formData.append('key', "qwerty")
        axios.post(`${process.env.REACT_APP_HOST}/fleetowners/v1/imageupload/`,
            formData, { headers: { 'x-api-key': `${process.env.REACT_APP_API_KEY}` } })
            .then(function (response) {
                // setValue('machine_pic', response.data.image_url);
                mulipleImages[i] = response?.data?.image_url
                setValue('machine_pic', mulipleImages);
                // SetMachineImage(response.data.image_url);
                SetLoading(false);
                // ChangeRequired();

            })
            .catch(function (error) {
                console.log(error.response);
                // if (error.response.status === 400) {
                //     alert('Something went worng ! Please contact your Admin.');
                // }
                if (error.response.status === 500) {
                    alert('Server error! Please contact your Admin.');
                }
            });
    }

    console.log(errors, 'errors')
    return (
        <>
            <div className="h-80 flex justify-center w-full  mt-2 py-2 relative ">
                {/* <label htmlFor="chage" className='p-2 bg-blue-300'>edit</label>
                <input type="file" id='chage' onChange={(e) => edit_one(e,0)} className='invisible' /> */}
                <div className="grid gap-1 grid-cols-3 grid-rows-2">
                    <div className='w-full h-30  relative '>


                        <input type="file" id='machine_front_side' className='w-full' name='machine_pic.0' title='Front Side Image' onChange={(e) => { uploadImage(e, 0) }} required />
                        <label htmlFor="machine_front_side" className='flex flex-col justify-center items-center  text-xs font-semibold cursor-pointer '>
                            {/* <AddIcon style={{ fontSize: "3.2rem" }} /> */}
                            Front Side Image
                        </label>

                        {
                            <img src={machine_image[0]} className='w-full h-full object-contain abosolute top-0 left-0' alt="" />
                        }

                    </div>
                    <div className='h-30 w-full  relative'>
                        <input type="file" id='machine_back_side' name='machine_back_side' className='invisible' onChange={(e) => { uploadImage(e, 1) }} />

                        {
                            machine_image[1] ? <div className=''>
                                <label htmlFor="machine_back_side" className='absolute block z-10 top-0 left-0 p-0 bg-blue-200 cursor-pointer'>edit</label>
                                <input type="file" id='machine_back_side' name='machine_back_side' className='invisible' onChange={(e) => { uploadImage(e, 1) }} />
                                
                                     <img src={machine_image[1]} id='machine_back_side' className='absolute top-0 left-0 w-full h-full object-contain mx-auto' alt="" /> 
                                
                            </div> : <label htmlFor="machine_back_side" className='absolute w-full h-full flex flex-col justify-center items-center border border text-xs font-semibold '>
                                <AddIcon style={{ fontSize: "3.2rem" }} />
                                Back Side Image
                            </label>
                        }
                    </div>


                    

                    {/* <div className='w-full h-40 border-slate-200 relative'>
                        <label htmlFor="machine_back_side" className='absolute w-full h-full flex flex-col justify-center items-center border border text-xs font-semibold cursor-pointer'>
                            <AddIcon style={{ fontSize: "3.2rem" }} />
                            Back Side Image
                        </label>
                        <input type="file" id='machine_back_side' name='machine_back_side' className='invisible hidden' onChange={(e) => { uploadImage(e, 1) }} />
                        <img src={machine_image[1]} className='w-full h-full' alt="" />
                       
                    </div> */}





                    <div className='h-30 w-full  relative'>
                        <input type="file" id='machine_left_side' name='machine_left_side' className='invisible' onChange={(e) => { uploadImage(e, 2) }} />

                        {
                            machine_image[2] ? <div className=''>
                                <label htmlFor="machine_left_side" className='absolute block z-10 top-0 left-0 p-0 bg-blue-200 cursor-pointer'>edit</label>
                                <input type="file" id='machine_left_side' name='machine_left_side' className='invisible' onChange={(e) => { uploadImage(e, 2) }} />
                                
                                     <img src={machine_image[2]} id='machine_left_side' className='absolute top-0 left-0 w-full h-full object-contain mx-auto' alt="" /> 
                                
                            </div> : <label htmlFor="machine_left_side" className='absolute w-full h-full flex flex-col justify-center items-center border border text-xs font-semibold '>
                                <AddIcon style={{ fontSize: "3.2rem" }} />
                                Right Side Image
                            </label>
                        }
                    </div>

                    {/* <div className='w-full  border-slate-200 relative'>
                        <label htmlFor="machine_left_side" className='absolute w-full h-full flex flex-col justify-center items-center border  text-xs font-semibold '>
                            <AddIcon style={{ fontSize: "3.2rem" }} className='' />
                            Left Side Image
                        </label>
                        <input type="file" id='machine_left_side' name='machine_left_side' className='invisible hidden' onChange={(e) => { uploadImage(e, 2) }} />
                        <img src={machine_image[2]} className='w-full h-full object-contain' alt="" />
                    </div> */}




                    <div className='h-30 w-full  relative'>
                        <input type="file" id='machine_right_side' name='machine_right_side' className='invisible' onChange={(e) => { uploadImage(e, 3) }} />

                        {
                            machine_image[3] ? <div className=''>
                                <label htmlFor="machine_right_side" className='absolute block z-10 top-0 left-0 p-0 bg-blue-200 cursor-pointer'>edit</label>
                                <input type="file" id='machine_right_side' name='machine_right_side' className='invisible' onChange={(e) => { uploadImage(e, 3) }} />
                                
                                     <img src={machine_image[3]} id='machine_right_side' className='absolute top-0 left-0 w-full h-full object-contain mx-auto' alt="" /> 
                                
                            </div> : <label htmlFor="machine_right_side" className='absolute w-full h-full flex flex-col justify-center items-center border border text-xs font-semibold '>
                                <AddIcon style={{ fontSize: "3.2rem" }} />
                                Left Side Image
                            </label>
                        }
                    </div>





























                    <div className='h-30 w-full  relative'>
                        <input type="file" id='tyre_image' name='tyre_image' className='invisible' onChange={(e) => { uploadImage(e, 4) }} />

                        {
                            machine_image[4] ? <div className=''>
                                <label htmlFor="tyre_image" className='absolute block z-10 top-0 left-0 p-0 bg-blue-200 cursor-pointer'>edit</label>
                                <input type="file" id='tyre_image' name='tyre_image' className='invisible' onChange={(e) => { uploadImage(e, 4) }} />
                                
                                     <img src={machine_image[4]} id='tyre_image' className='absolute top-0 left-0 w-full h-full object-contain mx-auto' alt="" /> 
                                
                            </div> : <label htmlFor="tyre_image" className='absolute w-full h-full flex flex-col justify-center items-center border border text-xs font-semibold '>
                                <AddIcon style={{ fontSize: "3.2rem" }} />
                                Tyre Image
                            </label>
                        }
                    </div>





























                    <div className='h-30 w-full  relative'>
                        <input type="file" id='machine_meter' name='machine_meter' className='invisible' onChange={(e) => { uploadImage(e, 5) }} />
                        {
                            machine_image[5] ? <div className=''>
                                <label htmlFor="machine_meter" className='absolute top-0 left-0 p-0 bg-blue-200 cursor-pointer z-10'>edit</label>
                                <input type="file" id='machine_meter' name='machine_meter' className='invisible' onChange={(e) => { uploadImage(e, 5) }} />
                                <img src={machine_image[5]} id='machine_meter' className='absolute top-0 left-0 w-full  h-full object-contain mx-auto' alt="" />
                            </div> : <label htmlFor="machine_meter" className='absolute  w-full h-full flex flex-col justify-center items-center border border text-xs font-semibold '>
                                <AddIcon style={{ fontSize: "3.2rem" }} />
                                Meter Image
                            </label>
                        }
                    </div>




                </div>








                {/* Working Code  */}



                {/* <input hidden type="file" {...register('machine_pic')} rules={{ required: "*required" }} /> */}


                {/* {
                    machine_image.length > 0 && <label htmlFor="mymachine"
                        className='absolute top-2 left-4 font-semibold text-sm bg-blue-100 p-2 rounded-md cursor-pointer border' >Edit</label>
                }
                <input style={{ display: machine_image.length > 0 ? 'none' : 'inline-block' }}
                    type="file" id='mymachine' name='machine_pic'
                    onChange={(e) => { uploadImage(e) }} />
                {
                    loading && <CircularProgress />
                }

                {
                    machine_image && <img src={machine_image} className='w-full h-full object-contain' alt="" />
                } */}
            </div>


        </>
    );
};

export default MachinePhotos