// Home Page Banner Api Endpoints
export const homebanner = '/showroom/api/v1/homebanner/list'
export const brandlist = '/showroom/api/v1/brand/all'
export const popular_machinery = '/showroom/api/v1/product_list/?popular=True'
export const vdo_listing = '/showroom/api/v1/video/list'
export const blog_listing = '/showroom/api/v1/blog/list'

// Single blog and vdo api list
export const singleBlog = '/showroom/api/v1/blog/list?q='
export const singleVdo = '/showroom/api/v1/video/list?q='


// ****---------------------------------------------------------------------------------****
// The Endpoints for new products allproducts at glance,cat,subcat listing,brand listing 
// ****---------------------------------------------------------------------------------****
export const allNewProducts = '/showroom/api/v1/product_search/?product_type=new'
export const allCategories = '/partner/v1/view_categories/?key=qwerty'
export const allSubCategories = '/showroom/api/v1/sub_category/all'

 
// home page subcategory listing api that is diff then on other pages
export const old_api_sub_category = '/showroom/api/v1/sub_category/'

// ****---------------------------------------------------------------------------------****
// The Endpoints for Used products allproducts at glance,cat,subcat listing,brand listing 
// ****---------------------------------------------------------------------------------****
export const verified_used_machine = '/partner/v1/machine_list?available_for_sell=True'
export const used_categorywise_product = '/partner/v1/machine_list?available_for_sell=True&q='
export const used_sub_categorywise_product = '/partner/v1/machine_list?available_for_sell=True&q='




