import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import GradeIcon from '@mui/icons-material/Grade';
// import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import StarIcon from '@mui/icons-material/Star';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProductStatus from "./ProductStatus";
// import Login from "./loginRegister/Login";
// import Modal from "../components/loginRegister/Product_popup"
// import Product_register_login from "../components/loginRegister/Product_register_login"
// import ModalQuickView from "./ModalQuickView";
import Button from '@mui/material/Button';
import ModalQuickViewUsed from "./ModelQuickViewUsed";
import ModalQuickView2 from "../components/ModelQuickView2";

import "./ProductCard.css"

export interface ProductCardProps { className?: string; data?: any; }
const ProductCardUsed: FC<ProductCardProps> = ({ className = "", data, }) => {
    const [showModalQuickView, setShowModalQuickView] = React.useState(false);
    var objdatasting = JSON.parse(localStorage.getItem("data")!)
    const { sub_category, category,
        product_name, image_url, id,
        slug, asking_price, hmr,
        Location, model_year, partner_name,
        rental_model, rental_brand, brand_id,
        rental_location, machine_category_id, machine_model,
        machine_pic, machine_id, territory, perchase_date, machine_number,status
    } = data;

    // console.log(Location, 'LocationLocationLocation')
    return (
        <>
            <div className="w-full h-full relative">
                <div
                    className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
                    data-nc-id="ProductCard"
                >
                    <Link to={"/product-detail"} className="absolute inset-0"></Link>
                    <div className="relative flex-shrink-0 overflow-hidden z-1 group the_container">
                        <Link to={`/used-machine-detail/${machine_id}/${machine_category_id}/${sub_category}/${brand_id}/`} className="block">
                            {/* <Link to={`/p/${id}/${slug}/${category}/${sub_category}`} className="block"> */}
                            <NcImage
                                title={product_name}
                                containerClassName="flex aspect-w-8 aspect-h-7 w-full h-0 image_url_border"
                                src={image_url || machine_pic[0] || ''}
                                className="object-contain border border w-full h-full  product_img"
                            />
                            <h2 className={`nc-ProductCard__title text-base transition-colors product_card_font_used text-center mt-2 lg:mt-2 `} title={product_name}>{machine_model}</h2>
                            {
                                perchase_date && <div className="flex justify-between my-1">
                                    <div className="asking_price">Rs.&nbsp;{asking_price}/-</div><div className="model_year" >&#128197; {perchase_date.slice(0, 4)}</div>
                                </div>
                            }

                            {
                                territory && <div className="flex justify-between font-semibold">
                                    {/* <div className="hmr"><AccessTimeFilledIcon style={{ fontSize: ".9rem" }} /> {hmr} Hrs</div> */}
                                    <div className="Location font-xs"><LocationOnIcon style={{ fontSize: ".9rem" }} /> {territory}</div>
                                    <div className="Location">{machine_category_id}</div>
                                </div>
                            }


                            <div className="flex justify-between">
                                <div className="rental_location">{machine_number}</div>
                                <div className="rental_price">{brand_id}</div>
                            </div>
                        </Link>
                        <ProductStatus status='Available' />
                        <div className="absolute top-0 right-1 bg-slate-200 px-1 rounded-lg">
                            <VisibilityIcon sx={{ fontSize: "1rem" }} />  <small style={{ fontSize: ".7rem" }}>{(Math.random() * 1 + 4).toFixed(1)}k</small>
                        </div>
                    </div>
                </div>

                {/* <div className="price_range mb-1">&#8377;From 9.5 to 12 Lac*</div> */}

                {
                    asking_price ? <Button variant="contained" className="w-full"
                        onClick={() => setShowModalQuickView(true)}
                        title="Enquire Now"
                    >Contact Seller</Button> : <Button variant="contained" className="w-full"
                        onClick={() => setShowModalQuickView(true)}
                        title="Enquire Now"
                    >Enquire now</Button>
                }


                <div>
                    {
                        objdatasting?.full_name ?
                            <ModalQuickViewUsed
                                id={machine_id}
                                product_name={machine_model}
                                show={showModalQuickView}
                                onCloseModalQuickView={() => setShowModalQuickView(false)}
                            />
                            :
                            <ModalQuickView2
                                show={showModalQuickView}
                                onCloseModalQuickView={() => setShowModalQuickView(false)}
                            />
                    }
                </div>


            </div>
        </>
    );
};

export default ProductCardUsed;
