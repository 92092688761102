import React, { createContext, useEffect, useState } from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import { Link } from "react-router-dom";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Helmet } from "react-helmet";
import axios from "axios";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionSliderProductCardLatest from "components/SectionSliderProductLatest";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import SearchTabs from "components/SectionHero/SearchTabs";
import SellBuyMob from "components/SectionHero/SellBuyMob";
import CustomBlog from "components/CustomBlog/CustomBlog";
import CustomVideos from 'components/CustomVideos/CustomVideos'
import CaroHero2 from '../../components/SectionHero/libCaro/CaroHero2'
import PopuBrands from "./brands/PopuBrands";
import BasicBreadcrumbs from "components/BreadComb/BreadComb";
import './home_page.css';
import { ArrowRightIcon } from "@heroicons/react/24/solid";
// import Input from "shared/Input/Input";
// import ButtonCircle from "shared/Button/ButtonCircle";
// import EmployLogin from "components/erp/EmployLogin";
// import { useParams } from "react-router-dom"
// import ScrollToTop from "routers/ScrollToTop";
// import CaroHero3 from "components/SectionHero/libCaro/CaroHero3";


export const GlobalInfo = createContext<any>('')
const HomePageData = { Home: "Home", Location: "Popular Machinery" }
function PageHome() {
  return (
    <div >
      <div className="nc-PageHome relative overflow-hidden">
        <Helmet>
          <title>AgrotracDigital:New Machinery,Rentals,Parts</title>
        </Helmet>

       

        <div className="my-3 md:my-0 banner_min_height">
          <CaroHero2 />
        </div>



        <div className="md:hidden">
          <SellBuyMob />
        </div>

        <div className="md:hidden my-5">
          <SearchTabs />
        </div>

        {/* <div className="container mt-6">

          <BasicBreadcrumbs sx='FontSize:.9rem' Popular={HomePageData} />
        </div> */}
        <div className="relative lg:space-y-8 mt-4">
          {/* SECTION */}
          <div className="container">
            <SectionSliderProductCard
              data={[
                PRODUCTS[4],
                SPORT_PRODUCTS[5],
                PRODUCTS[7],
                SPORT_PRODUCTS[1],
                PRODUCTS[6],
              ]}
            />
          </div>

          <div className="container flex items-center justify-center">
            <img src="images/business-strategy-leaderboard-ad.jpg" alt="" />
          </div>

          <div className="how_it_works_bg p-4">
            <h2 className="mt-3 mb-8 text-center text-lg font-medium text-[#1550A1]" >Complete Solutions for Agri Machinery</h2>
            <div className="container">
              <SectionHowItWork />
            </div>
          </div>

          <div className="mt-6 container">
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-3">
                <SectionSliderProductCardLatest
                  heading="Latest Machinery"
                  subHeading="from 2022"
                  url='/showroom/api/v1/product_list/'
                />
              </div>
              
              <div className="col-span-1">
                <div className="advertisement pt-10">
                  <img src="images/441927465_972805741300417_7932176696816619435_n.jpg" alt="" className="rounded-md" />
                </div>
              </div>
            </div>
            
          </div>



          {/* -------------------------**********************------------------
          search by Brand name
          -----------------------*******************----------------------- */}

          <div className="produc_card_bacground container">
            <div className="h-full w-full">
              <div className={`nc-SectionSliderProductCard mb-3`}>

                <Heading
                  className={` mb-3`}

                >
                  {`Popular Brands`}
                </Heading>
                <div className="popularBrandLogos">
                  <PopuBrands />
                </div>
              </div>
            </div>

          </div>




          {/* SECTION Category and Sub_Cat */}
          <div className="relative py-1 lg:py-1.5">
            <BackgroundSection />
            <SectionGridMoreExplore />
          </div>

          {/* <SectionSliderProductCard
          heading="TOP SEARCHED PRODUCTS"
          subHeading=""
        /> */}


          

          {/* SECTION */}
          {/* <SectionPromo1 /> */}


          {/*  */}
          {/* <SectionPromo2 /> */}

          {/* SECTION 3 */}
          {/* <SectionSliderLargeProduct cardStyle="style2" /> */}

          {/* <SectionGridFeatureItems /> */}
          <div className="container relative" id="agro_blog_sec">
            <Heading rightDescText="" className="mt-6 pb-4">
                Latest Blogs
              </Heading>
              {/* <SectionMagazine5 /> */}
              <CustomBlog />
              <div className="text-right mt-3 ">
                <Link to='/all-blog-posts' className="text-[14px] font-medium text-[#1550A1] flex items-center justify-end">
                  Explore All 
                  <span className="inline-block w-[20px] h[20px] ml-2">
                    <ArrowRightIcon fontSize={10} />
                  </span>
                </Link>

              </div>
          </div>


          <div className="container relative">
            <Heading rightDescText="" className="mt-6 py-4">
              Latest Videos
            </Heading>

            <CustomVideos />
            <div className="text-right mt-3 ">
                <Link to='/' className="text-[14px] font-medium text-[#1550A1] flex items-center justify-end">
                  Explore All 
                  <span className="inline-block w-[20px] h[20px] ml-2">
                    <ArrowRightIcon fontSize={10} />
                  </span>
                </Link>

              </div>
          </div>

          {/*  */}
          <div className="bg-[#E8E8E8] py-5">
            <SectionClientSay />
          </div>
          {/* SECTION */}

          {/* SECTION */}

          {/* <div className="mt-24 py-12" >
            <SectionPromo3 />
          </div> */}
        </div>
        {/* <EmployLogin/> */}

      </div>
    </div>
  );
}

export default PageHome;












