import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import { setdatalocal } from "loginUtils/Common";
import { getUser } from "loginUtils/Common";
import swal from 'sweetalert';
import { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import './img/traxi_login.css'
import { current } from "@reduxjs/toolkit";
import { count } from "console";
import Button from '@mui/material/Button';
import { Redirect } from 'react-router'
import { parse } from "path";


export interface PageLoginProps { className?: string; onCloseModalQuickView?: any }

const loginSocials = [
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "", onCloseModalQuickView }) => {
  const [showOtpDiv, setShowOtpDiv] = useState(false)
  const [profile, setProfile] = useState()
  const [loading, setLoading] = useState(true)
  const [set, Reset] = useState(false)
  const [number, setPhone] = useState()
  const [otp, setOtp] = useState()
  const [err, setErr] = useState('')
  const [PhoneTrue, setPhoneTrue] = useState(true)
  const [trigger, setTrigger] = useState(false);
  const [isAuthentcated, SetIsAuthenticated] = useState(false);
  // const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(59)

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        if (seconds === 0) {
          return false
        }
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(0);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, trigger]);

  // -------------------------------------------------------------------
  // --------Send OTP to given input Number ---------------------
  // -------------------------------------------------------------------

  const getOTP = async () => {
    const config = { headers: { "X-Api-Key": `${process.env.REACT_APP_API_KEY}` } };
    try {
      setLoading(true)
      let URL = `${process.env.REACT_APP_HOST}`
      let ProductUrl = `${URL}/partner/v1/otp-login/`
      const RESULT = await axios.post(ProductUrl, {
        mobile_number: number,
        key:'qwerty'
      }, config)
      setProfile(RESULT.data)
      setLoading(false)
      Reset(true)
      if (RESULT.status === 200) {
        swal({
          title: "Success!",
          className: "otp_container",
          text: "OTP sent!",
          dangerMode: false,
          timer: 2000
        });
        setShowOtpDiv(true)
      }
    }
    catch (err: any) {
      if (err.response.data.status === 404) swal("Error!", "User not found, check your username", "error");
    }
  }
  // -------------------------------------------------------------------
  // --------Fetch User Profile After OTP verified ---------------------
  // -------------------------------------------------------------------
  const getUserProfile = async () => {
    const config = { headers: { "X-Api-Key": `${process.env.REACT_APP_API_KEY}` } };
    try {
      setLoading(true)
      let URL = `${process.env.REACT_APP_HOST}`
      let ProductUrl = `${URL}/partner/v1/otp-verify/`
      const RESULT = await axios.post(ProductUrl, {
        number: number,
        otp: otp,
      }, config)
      setProfile(RESULT.data)
      setLoading(false)
      setdatalocal(RESULT.data.token, RESULT.data.data)
      Reset(true)
      if (RESULT.status === 200) window.location.reload()
      if (RESULT.status === 240) swal("Error!", "Invalid otp or phone number", "error")
    }
    catch (err: any) {
      console.log(err.response)
      if (err.response.data.status === 240) {
        swal("Error!", "Invalid otp or phone number", "error")
      }
    }
  }

  const handleContinue2 = (e: any) => {
    e.preventDefault();

    if (number === undefined || otp === undefined) setErr("Fields are required")
    else getUserProfile();
  }

  const handleContinue = (e: any) => {
    e.preventDefault();
    setTrigger(true)
    setSeconds(59);
    if (number === undefined) setErr("fields are required")
    // else if (PhoneTrue) setErr('please check terms and conditions')
    else if (number < 13) setErr("phone number cannot be less than 10 digit")
    else getOTP();
  }

  const handlePhone = (e: any) => {
    if (e === undefined || '') setErr("Phone number cannot be empty")
    else if (e?.length > 0) setErr("")
    else if (e?.length > 12) setErr("Phone number cannot be more than 10 digit")
    setPhone(e)
  }
  const handleOtp = (e: any) => {
    e.preventDefault();
    setOtp(e.target.value)
  }

  const GetCheckbox = (e: any) => {
    setPhoneTrue((current) => !current)
    if (PhoneTrue) {
      setErr('')
    }
  }


  useEffect(() => {

  }, [isAuthentcated]);



  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>AgrotracDigital:Login/Register</title>
      </Helmet>
      <div className="container-fluid p-4 shadow-lg">
        <div className="max-w-sm mx-auto">
          {/* FORM */}
          <form className="grid grid-cols-1 py-4 pt-0" method="POST">
            <small>{err && <div className="bg-red-400 px-1 text-white mb-1 text-xs rounded-md">{err}</div>} </small>
            <PhoneInput
              international={false}
              defaultCountry="IN"
              placeholder="Enter mobile number"
              className="border-none w-full rounded-lg bg-primary-200 px-5 mb-2 text-xs"
              value={number}
              onChange={(e) => handlePhone(e)}
              rules={{ required: true }}
              Maxlength="11"
              id='PageLoginPhone'
              pattern="[0-9]{10}"
              error={number ? (isValidPhoneNumber(number) ? undefined : 'Invalid phone number') : 'Phone number required'} />
            {
              showOtpDiv ? <div>
                <label className="block">
                  <span className="flex justify-between items-center font-semibold dark:text-neutral-200">
                    Enter OTP
                  </span>
                  <Input type="password"
                    className="mt-1"
                    value={otp}
                    onChange={(e) => handleOtp(e)}
                  />
                </label>
                <div className="countdown-text flex justify-around mt-2 py-2">
                  <p>00:{seconds}</p>
                  <button className={`p-1 rounded-sm bg-blue-300 text-xs ${seconds > 0 && "bg-slate-200"}`} disabled={seconds > 0} onClick={(e) => handleContinue(e)}>Resend OTP</button>
                </div>
                <Button variant="contained" type="submit" className="w-full rounded-lg bg-primary-300 py-2 px-1 font-semibold mt-4"
                  onClick={(e) => handleContinue2(e)}

                >Continue</Button>
              </div> :
                <div>
                  
                  <Button variant="contained" type="submit"
                    className="w-full"
                    sx={{ marginTop: "10px" }}
                    onClick={(e) => handleContinue(e)}
                  >SEND OTP</Button>
                  <div className="mt-3"> 
                    <label htmlFor="" className="" ><a href="/privacy-policy" target='_blank' style={{ fontSize: '.6rem',color:'rgb(122, 120, 120)' }}>By continuing I agree with the  <label className="underline underline-offset-2">Privacy Policy</label>, <label className="underline underline-offset-2">Terms & Conditions</label></a></label></div>
                </div>
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
