import axios from "axios";
import { config, tld } from "services/globalvariables/globalvariables";
import { allCategories, allSubCategories, allNewProducts } from 'services/api/allApiHub'

// --------------------------------------------------------------------
// ... Function to get Category list ...
// --------------------------------------------------------------------
export const getCategory = async () => {
    try { return await axios.get(`${tld}${allCategories}`, config).data.data?.filter(i => i.sub_category?.length > 0) }
    catch (err) { console.log(err) }
}

// --------------------------------------------------------------------
// ... Function to get subCategory list ...
// --------------------------------------------------------------------
export const getSubCategory = async () => {
    try { return await axios.get(`${tld}${allSubCategories}`, config).data.data } catch (err) { console.log(err) }
}

// --------------------------------------------------------------------
// ... Get all new products at buy new click tab ...
// --------------------------------------------------------------------
export const getAllProducts = async () => {
    try {
        const RESULT = await axios.get(`${tld}${allNewProducts}`, config)
        return RESULT.data.data
    }
    catch (err) { console.log(err) }
}


export const GetCategoryProduct = async (id) => {
    try {
        let ProductUrl = `${tld}/showroom/api/v1/product_list/?q=${id}&filter_by_category=machine_category_id`
        const RESULT = await axios.get(ProductUrl, config)
        return RESULT.data.data
    } catch (err) { console.log(err) }
}