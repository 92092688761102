import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment } from "react";
import { useLocation } from "react-router-dom";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BasicBreadcrumbs from "components/BreadComb/BreadComb";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skeleton from '@mui/material/Skeleton';
import PageLogin from "containers/PageLogin/PageLogin";
import Button from '@mui/material/Button';
import axios from "axios";
import { useQuery } from 'react-query'
import { useState } from "react";

export interface ModalQuickViewProps {
    show: boolean;
    onCloseModalQuickView: () => void;
    GetCategoryProduct: any;
    GetsubCategoryProduct: any;
    GetListByproductType: any;
}

const ModelQuickProductUsed: FC<ModalQuickViewProps> = ({ show, onCloseModalQuickView, GetCategoryProduct, GetsubCategoryProduct, GetListByproductType }) => {
    const location = useLocation();

    const getCategory = async () => {
        const config = { headers: { "X-Api-Key": `${process.env.REACT_APP_API_KEY}` } }
        try {
            let URL = `${process.env.REACT_APP_HOST}`
            let ProductUrl = `${URL}/fleetowners/v1/view_categories/?key=qwerty`
            const RESULT = await axios.get(ProductUrl, config)
            return RESULT.data.data?.filter((i: any) => i.sub_category?.length > 0)
        }
        catch (err) { console.log(err) }
    }
    const { data: category, isError: isErrorGetCagegory } = useQuery('getCategoryList', getCategory, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })



    const getSubCategory = async () => {
        const config = { headers: { "X-Api-Key": `${process.env.REACT_APP_API_KEY}` } }
        try {
            let URL = `${process.env.REACT_APP_HOST}`
            let ProductUrl = `${URL}/showroom/api/v1/sub_category/all`
            const RESULT = await axios.get(ProductUrl, config)
            return RESULT.data.data
        }
        catch (err) { console.log(err) }
    }
    const { isLoading: isLoadingGetCategory, data: sub_category, isError: isErrorGetSubCagegory } = useQuery('getSubCategoryList', getSubCategory, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })


    const StopIt = (e: any) => {
        e.preventDefault();
    }

    //Dialog Overlay

    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-scroll"
                onClose={onCloseModalQuickView}
            >
                <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="relative inline-flex xl:py-8 w-full max-w-md max-h-full">
                            <div
                                className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
          dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
                            >
                                <span className="absolute right-3 top-2 z-50 bg-slate-100 rounded-2xl">
                                    <ButtonClose onClick={onCloseModalQuickView} />
                                </span>

                                <div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
                                    <div className="relative  flex justify-between z-10 w-full my-4 sticky-0">
                                        <Button variant="contained" sx={{ marginRight: "3px" }} className="w-full ml-1" onClick={onCloseModalQuickView}>Reset</Button>
                                        <Button variant="contained" className="w-full" onClick={onCloseModalQuickView}>Apply</Button>
                                    </div>

                                    <Accordion expanded={true} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography sx={{ fontSize: ".9rem" }}>Filter By Category</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <div className="h-40 overflow-y-scroll scrollbar px-2">
                                                    {
                                                        category?.map((item: any, number: any) => {
                                                            return <div key={number} className="flex items-center py-1"><input type="radio" name="the" id={item?.machine_category_id}
                                                                onChange={(e) => GetCategoryProduct(item?.machine_category_id)} />
                                                                <label className='ml-2 text-xs  hover:bg-slate-100 p-1  cursor-pointer rounded-md w-full block'>{item?.machine_category_name}</label>   </div>
                                                        })
                                                    }
                                                </div>

                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>


                                    {/* ------------------------------ */}
                                    {/* Search By Sub Category */}
                                    {/* ------------------------------ */}
                                    <Accordion expanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Search By Subcategory</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>

                                                <div className="mt-2 h-40 overflow-y-scroll scrollbar px-2">
                                                    {
                                                        sub_category ? sub_category?.map((item: any, number: number) => {
                                                            return <div key={number} className="flex items-center py-1"><input type="radio" name="the" id={item.id} onChange={() => GetsubCategoryProduct(item?.id)} /><label className='ml-1 text-xs  hover:bg-slate-100 p-1  cursor-pointer rounded-md w-full block '>{item?.sub_category}</label></div>
                                                        }) : <Skeleton variant="rectangular" width="100%">
                                                            <div style={{ paddingTop: '57%' }} />
                                                        </Skeleton>
                                                    }
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>



                                    <Accordion expanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Search By Brand</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                                <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >FarmTrac</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >John Deere</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >New Holland</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Powertrac</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Massey Ferguson</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>



                                    {/* ---------------------------------------
                               ------------ Search by Buzet------------
                                --------------------------------------- */}
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Search By Budget</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <input type="range" min='50000' max='4000000' className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                                <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2 text-sm" >0 lakh to 2 lakh</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >2 lakh to 4 lakh</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >4 lakh to 8lakh</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >4 lakh to 8lakh</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >4 lakh to 8lakh</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >4 lakh to 8lakh</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >4 lakh to 8lakh</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Powertrac</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Massey Ferguson</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="radio" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>


                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Search By Model year</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <input type="search" className="w-full border rounded border-slate-200" placeholder="&#128269; search here." />
                                                <div className="mt-2 h-40 overflow-y-scroll scrollbar">
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2 text-sm" >FarmTrac</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >John Deere</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >New Holland</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Powertrac</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Massey Ferguson</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                    <div className="flex items-center mt-2"><input type="checkbox" name="the" /><span className="ml-2" >Eicher Tractors</span></div>
                                                </div>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>


                                    {/* <button className="block md:hidden w-full mt-6 text-white py-2 mb-2" style={{ background: "#4271E6" }} >Cancel</button> */}
                                    <Button variant="contained" sx={{ marginBottom: "10px", marginTop: "2rem" }} className="absolute bottom-0 left-0 w-full" onClick={onCloseModalQuickView}>Apply</Button>
                                </div>

                            </div>

                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ModelQuickProductUsed;
