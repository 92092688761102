import { useState } from "react";
import { Helmet } from "react-helmet";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import ProductCard from "components/ProductCard";
// import { useParams } from "react-router-dom"
import axios from "axios";
import "./all_products.css"
// import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BasicBreadcrumbs from "components/BreadComb/BreadComb";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query'
import ModalQuickProductFilter from "components/ModelQuickProductFilter";
// import { getCategory } from "./newproduct";
// import ModelQuickAllpro from "components/ModelQuickAllpro";
import ShimmerUI from "./shimmer/ShimmerUI";
import { config, tld } from "services/globalvariables/globalvariables";

import { getCategory, getSubCategory, getAllProducts } from "./newProductsApi";

const Allproducts = () => {
    const HomePageData = { Home: "Home", Location: "New Products" }
    const [products, setProdcuts] = useState([])
    const [hidden, setHidden] = useState('hidden');
    const [showModalQuickView, setShowModalQuickView] = useState(false);
    // ... Function to get All data at very first time ...
    const { data: prod, isLoading } = useQuery('newProducts', getAllProducts, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })


    // ... Function to get Category list ...
    const { data: category } = useQuery('getCategoryList', getCategory, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })


    // ... Function to get sub category list ...
    const { data: sub_category } = useQuery('getSubCategoryList', getSubCategory, { staleTime: 15 * (60 * 1000), cacheTime: 18 * (60 * 1000) })
    const GetCategoryProduct = async (id) => {
        try {
            const RESULT = await axios.get(`${tld}/showroom/api/v1/product_list/?q=${id}&filter_by_category=machine_category_id`, config)
            setProdcuts(RESULT.data.data)
        }
        catch (err) { console.log(err) }
    }


    //... Function to get all products based on its subcatergoy ...
    const GetsubCategoryProduct = async (id) => {
        try {
            const RESULT = await axios.get(`${tld}/showroom/api/v1/product_search/?sub_category=${id}`, config)
            setProdcuts(RESULT.data.data)
        }
        catch (err) { console.log(err) }
    }

    // Function to get all products filtered on type i.e new/used ........
    const GetListByproductType = async (type) => {
        try {
            const RESULT = await axios.get(`${tld}/showroom/api/v1/product_search/?product_type=${type}`, config)
            setProdcuts(RESULT.data.data)
        } catch (err) { console.log(err) }
    }


    return (
        <div className={`nc-PageSearch`} data-nc-id="PageSearch">
            <Helmet>
                <title>AgroTracDigital All Products | Buy best Agri Machinery | Proguard | Harvester | Rotavator</title>
                <meta />
            </Helmet>
            <div
                className={`nc-HeadBackgroundCommon  top-0 left-0 right-0 w-full bg-primary-200 dark:bg-neutral-800/20`}
                data-nc-id="HeadBackgroundCommon"
            />

            <div className="container my-4 hidden md:block">
                <BasicBreadcrumbs Popular={HomePageData} />
            </div>

            <div className="container lg:pb-28 mt-4 min-h-screen md:min-h-0">
                <main className="grid grid-cols-1  md:grid-cols-4 sticky top-20">

                    {/* FILTER */}
                    {/* ------------------------------ */}
                    {/* Search By Sub Category */}
                    {/* ------------------------------ */}
                    <div className="">
                        <div className={`for_border md:mr-1 p-1 ${hidden}  md:block   w-full `} style={{ height: "1400px" }} >
                            <div className="">
                                <Accordion expanded={true} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Category</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className="h-40 overflow-y-scroll scrollbar px-2">

                                                {

                                                    category?.map((item) => {
                                                        return <div key={item?.machine_category_id} className="flex items-center py-1">
                                                            <input type="radio" name="the" id={item?.machine_category_id} onChange={() => GetCategoryProduct(item?.machine_category_id)} />
                                                            <label htmlFor={item?.machine_category_id} className='ml-2 text-xs  hover:bg-slate-100 p-1  cursor-pointer rounded-md w-full block'>{item?.machine_category_name}</label>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>


                                {/* ------------------------------ */}
                                {/* Search By Sub Category */}
                                {/* ------------------------------ */}
                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Subcategory</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>

                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar px-2">
                                                {
                                                    sub_category ? sub_category?.map((item) => {
                                                        return <div key={item.id} className="flex items-center py-1">
                                                            <input type="radio" name="the" id={item.id} onChange={() => GetsubCategoryProduct(item?.id)} />
                                                            <label htmlFor={item?.id} className='ml-1 text-xs  hover:bg-slate-100 p-1  cursor-pointer rounded-md w-full block '>{item?.sub_category}</label>
                                                        </div>
                                                    }) : null
                                                }
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* ------------------------------ */}
                                {/* Search By Product Condition */}
                                {/* ------------------------------ */}
                                <Accordion expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Search By Product Type</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className="mt-2 h-40 overflow-y-scroll scrollbar px-2">
                                                <div className="" id="all_pro_by_cat">
                                                    <div className="flex items-center py-1">
                                                        <input type="radio" name="the" id='newproduct' onClick={() => GetListByproductType("new")} />
                                                        <label htmlFor='newproduct' className='ml-1 text-xs  hover:bg-slate-100 p-1  cursor-pointer rounded-md w-full block '>New</label>
                                                    </div>
                                                    <div className="flex items-center py-1">
                                                        <input type="radio" name="the" id='usedproduct' onClick={() => GetListByproductType("used")} />
                                                        <label htmlFor='usedproduct' className='ml-1 text-xs  hover:bg-slate-100 p-1  cursor-pointer rounded-md w-full block '>Used</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </div>
                    </div>

                    <div className="col-span-3">
                        <div className={`grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-items-center  rounded md:px-2`}>
                            {products?.length === 0 ? prod && prod?.map((item, index) => <ProductCard data={item} key={index} />
                            )
                                : products?.map((item, index) => <ProductCard data={item} key={index} />
                                )}
                        </div>
                        {
                            <div className={`grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-items-center  rounded md:px-2`}>
                                {
                                    isLoading && [1, 2, 3, 4, 5, 6, 7, 8, 9,10,11,12].map((item, index) => <ShimmerUI />)
                                }
                            </div>
                        }
                    </div>
                </main>
                <div className="md:hidden">
                    <a href="#" class="float">
                        <i class="fa  my-float" style={{ fontSize: "15px" }} onClick={() => setShowModalQuickView(true)}>
                            <FilterAltIcon style={{ fontSize: '18px' }} />
                            FILTER</i>
                    </a>
                </div>
            </div>

            <ModalQuickProductFilter
                GetCategoryProduct={GetCategoryProduct}
                GetsubCategoryProduct={GetsubCategoryProduct}
                GetListByproductType={GetListByproductType}
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
            />
        </div >
    )
}

export default Allproducts