// import { _getImgRd, _getTagNameRd } from "contains/fakeData";
import React, { FC } from "react";
import { NavLink, Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import SectionSliderCustom from "../../components/SectionSliderCustom"
import ModalQuickView4 from "components/ModelQuickView4";
import './SubCategoryIcon.css'
export interface CardCategory1Props {
  className?: string;
  size?: "large" | "normal";
  featuredImage?: string;
  name?: string;
  desc?: string;
  id?: any;
}

const CardCategory1: FC<CardCategory1Props> = ({
  className = "",
  size = "normal",
  name = "",
  desc = "",
  featuredImage = "",
  id = ''
}) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const sub_category_filter = (e: any) => {
    e.preventDefault();
  }


  return (
    <NavLink
    to={"#"}
    className={`rounded-full bg-[#E7E7E7] px-4 py-2 mr-3 ${className}`}
    data-nc-id="CardCategory1"
    onClick={() => setShowModalQuickView(true)}
  >
    {/* <div className="">
      <NcImage
        containerClassName={`flex-shrink-0 ${size === "large" ? "w-10 h-10" : "w-8 h-8 md:w-12 md:h-12"
          } rounded-lg mr-3`}
        src={featuredImage}
        style={{ border: "2px solid #e4e4e9", borderRadius: "10px", cursor: "pointer" }}
      />
    </div> */}
    {name}

    {/* <div>
    <ModalQuickView4
      show={showModalQuickView}
      onCloseModalQuickView={() => setShowModalQuickView(false)}
      id={id}
    />
    </div> */}
    
  </NavLink>
    
  );
};

export default CardCategory1;
