import { Link } from "react-router-dom"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { getAllBlogList } from "services/globalLibrary/lib"
import DetailPageShimmer from "components/detailPageShimmer/DetailPageShimmer"
import "./customblog.css"
import { useQuery } from 'react-query'
import NcImage from "shared/NcImage/NcImage";

const Allblog = () => {
    const { isLoading, data, isError } = useQuery('getAllBlogList', getAllBlogList, { staleTime: 15 * (60 * 1000), cacheTime: 15 * (60 * 1000) })
    return <div className="min-h-screen mb-56">
        <div className="container grid grid-cols-1 md:grid-cols-4 mt-12 relative ">
            <div className="col-span-3 md:mx-6">
                <div className="">
                    {
                        isLoading ?
                            <DetailPageShimmer />
                            : data?.map((item, number) => {
                                return <Link to={`/blog-single/${item?.slug}`} className="relative" key={number}>
                                    <div className="try_border">
                                        <div class="flex flex-col md:flex-row m-2 table_heigt_blog">
                                            <div className='blog_img'>
                                                <img src={item?.image_url} className='object-contain the_blog_img' alt="" />
                                            </div>
                                            <div className='first flex flex-col justify-between md:ml-3'>
                                                <ul>
                                                    <div className="blog_heading_text mb-2">{item?.title}</div>
                                                    <h4 className="blog_desc_text desc" dangerouslySetInnerHTML={{ __html: item?.description }}></h4>
                                                </ul>
                                                <div className='flex justify-between mt-2'>
                                                    <p className="font-medium " style={{ fontSize: "14px" }}><AccountCircleIcon /> {item.author}</p>
                                                    <p className="font-light sm:hidden md:block" style={{ fontSize: "14px" }}>{item?.created_at.slice(0, 11)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            })
                    }
                </div>
            </div>
            <div className="mx-6 mt-12 md:mt-0 mb-24 md:mb-0 sticky top-0 ">
                <h2 className="bg-blue-200 px-2  md:mt-2">Recents Posts</h2>
                <ul className="mt-2">

                    {
                        data ? data?.map((item, number) => {
                            return <Link to={`/blog-single/${item.slug}`} className='text-xs underline block my-3 text-blue-800 text-justify'>{item.title}</Link>
                        })
                            : <DetailPageShimmer />
                    }
                </ul>
                {/* <div className="mt-8">
                    <h2 className="bg-blue-200 px-2 rounded-md">Related Posts</h2>
                    <ul>
                        {
                            data?.map((item, number) => {
                                return <Link to={`/blog-single/${item.slug}`} className='text-xs underline my-3 block text-blue-800 text-justify'>{item.title}</Link>
                            })
                        }
                    </ul>
                </div> */}
            </div>
        </div>






        <div className="relative  dark:bg-neutral-800 py-16 lg:py-28 mt-4">
            <div className="container ">
                <h2 className="text-3xl font-semibold">Related posts</h2>
                <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                    {data?.filter((_, i) => i < 8).map((item, index) => {
                        // console.log(item, 'item....................');
                        return (
                            <div
                                key={index}
                                className="relative aspect-w-3 aspect-h-4 rounded-md overflow-hidden group"
                            >
                                {/* <Link to={`/blog-single/${item?.slug}`} /> */}
                                <NcImage
                                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                                    // src={_getImgRd()}
                                    src={item?.image_url}
                                />
                                <div>
                                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                                </div>
                                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                                    {/* <Badge name="Categories" /> */}
                                    <h2 className="block text-lg font-semibold text-white ">
                                        {/* <span className="line-clamp-2">{_getTitleRd()}</span> */}
                                        <span className="line-clamp-2" dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                    </h2>

                                    <div className="flex">
                                        <span className="block text-neutral-200 hover:text-white font-medium truncate">
                                            {/* {_getPersonNameRd()} */}
                                            {item?.author}
                                        </span>
                                        <span className="mx-1.5 font-medium">·</span>
                                        <span className="font-normal truncate">{item?.created_at.slice(0, 10)}</span>
                                    </div>
                                </div>
                                <Link to={`/blog-single/${item?.slug}`} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    </div>
}

export default Allblog