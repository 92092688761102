export const data = {
    "product": [
        {
            "id": 7,
            "popular": false,
            "product_name": "Drone Sprayer",
            "product_type": "new",
            "image_url": "https://images.pexels.com/photos/2933243/pexels-photo-2933243.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        },
        {
            "id": 13,
            "popular": false,
            "product_name": "ROTAVATOR - PUDDLING SPECIAL",
            "product_type": "new",
            "image_url": "https://images.pexels.com/photos/1116035/pexels-photo-1116035.jpeg?auto=compress&cs=tinysrgb&w=1600",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        },
        {
            "id": 6,
            "popular": false,
            "product_name": "Rice Transplanter",
            "product_type": "new",
            "image_url": "https://images.pexels.com/photos/2252618/pexels-photo-2252618.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        },
        {
            "id": 8,
            "popular": false,
            "product_name": "Mist Trac 600 Single Fan",
            "product_type": "new",
            "image_url": "https://traxi-kyc-media.objectstore.e2enetworks.net/MistTrac_600_Front_Vis.__1_-removebg-preview.png",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        },
        {
            "id": 4,
            "popular": true,
            "product_name": "ESCORTS SELF PROPELLED 2WD / 4WD COMBINE HARVESTER",
            "product_type": "new",
            "image_url": "https://images.pexels.com/photos/2252618/pexels-photo-2252618.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        },

        {
            "id": 14,
            "popular": false,
            "product_name": "ROTAVATOR - SUPREME",
            "product_type": "new",
            "image_url": "https://traxi-kyc-media.objectstore.e2enetworks.net/rotavator-04-min.png",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        },
        {
            "id": 12,
            "popular": true,
            "product_name": "ROTAVATOR - FM+ SMART",
            "product_type": "new",
            "image_url": "https://images.pexels.com/photos/2889442/pexels-photo-2889442.jpeg?auto=compress&cs=tinysrgb&w=1600",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        },
        {
            "id": 11,
            "popular": false,
            "product_name": "ROTAVATOR - FM+ SUPER",
            "product_type": "new",
            "image_url": "https://traxi-kyc-media.objectstore.e2enetworks.net/rotavator-01..png",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        },
        {
            "id": 3,
            "popular": false,
            "product_name": "Farm Power_ Mini Round Baler",
            "product_type": "new",
            "image_url": "https://traxi-kyc-media.objectstore.e2enetworks.net/Mini Round Baler 2..png",
            "slug": "",
            "partner_name": "Vijay kumar",
            "rental_brand": "Escorts",
            "rental_location": "Faridabad, Haryana, 121009"

        }
    ],

}